$a-delay: 220ms;

.logo--vl {
	&__loader {
		h3 {
			font-family: $font-sans;
			@include fontsize(medium, responsive);
			text-transform: none;
			margin-bottom: 2vw !important;
			@include bp(mobile) {
				margin-bottom: 3vw !important;

			}
		}

		.payoff--items {
			display: none;
			visibility: hidden;
		}

		h4.chosenPayoff {
			// position: absolute;
			// top: 0;
			// left: 0;
			font-family: $font-till;
			text-transform: uppercase;
			@include fontsize(big, responsive);
			margin: 0;

			&.animated {
				animation: hideAndSeek $a-delay linear forwards;
				visibility: hidden;
				background-color: $c-black;
				width: 100%;
				@for $i from 1 through 20 {
					&:nth-child(#{$i}) {
						animation-delay: $i * $a-delay;
					}

					&:first-child {
						z-index: $i;
					}

					&:last-child {
						animation: stayThere $a-delay linear forwards;
						z-index: - $i;
					}
				}
			}
		}
	}

	&__header {
		font-family: $font-sans;
		letter-spacing: 0;
		margin: 0;
		@include fontsize(medium, responsive);

		&__light {
			color: $c-white;
		}
	}
}
@keyframes hideAndSeek {
	0% {
		visibility: visible;
	}

	100% {
		visibility: hidden;
	}
}
@keyframes stayThere {
	50% {
		visibility: hidden;
	}

	100% {
		visibility: visible;
	}
}
