@charset "UTF-8";
/* Fonts */
@font-face {
  font-family: 'plain-medium';
  src: url("../fonts/plain-medium.eot");
  src: url("../fonts/plain-medium.woff2") format("woff2"), url("../fonts/plain-medium.woff") format("woff"), url("../fonts/plain-medium.ttf") format("truetype"), url("../fonts/plain-medium.svg#plain-medium") format("svg"), url("../fonts/plain-medium.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'VL_handwrite_V1.0.1';
  src: url("../fonts/VL_handwrite_V101-regular.eot");
  src: url("../fonts/VL_handwrite_V101-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/VL_handwrite_V101-regular.woff2") format("woff2"), url("../fonts/VL_handwrite_V101-regular.woff") format("woff"), url("../fonts/VL_handwrite_V101-regular.ttf") format("truetype"), url("../fonts/VL_handwrite_V101-regular.svg#VL_handwrite_V1.0.1-regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Till2_37-Regular';
  src: url("../fonts/Till2_37-Regular.eot");
  src: url("../fonts/Till2_37-Regular.woff2") format("woff2"), url("../fonts/Till2_37-Regular.woff") format("woff"), url("../fonts/Till2_37-Regular.ttf") format("truetype"), url("../fonts/Till2_37-Regular.svg#Till2_37-Regular") format("svg"), url("../fonts/Till2_37-Regular.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'VL_handwrite_V1.0.2-regular';
  src: url("../fonts/VL_handwrite_V1.0.2-regular.eot");
  src: url("../fonts/VL_handwrite_V1.0.2-regular.woff2") format("woff2"), url("../fonts/VL_handwrite_V1.0.2-regular.woff") format("woff"), url("../fonts/VL_handwrite_V1.0.2-regular.ttf") format("truetype"), url("../fonts/VL_handwrite_V1.0.2-regular.svg#Till2_37-Regular") format("svg"), url("../fonts/VL_handwrite_V1.0.2-regular.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

t-face {
  font-family: 'custom_icons';
  src: url(../fonts/custom_icons.eot?dxlehx);
  src: url(../fonts/custom_icons.eot?#iefixdxlehx) format("embedded-opentype"), url(../fonts/custom_icons.ttf?dxlehx) format("truetype"), url(../fonts/custom_icons.woff?dxlehx) format("woff"), url(../fonts/custom_icons.svg?dxlehx#custom_icons) format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  font-family: 'custom_icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-arrow-down:before {
  content: "\e600"; }

.icon-arrow-left:before {
  content: "\e601"; }

.icon-arrow-right:before {
  content: "\e602"; }

.icon-arrow-up:before {
  content: "\e603"; }

.icon-back:before {
  content: "\e604"; }

.icon-close:before {
  content: "\e605"; }

.icon-question-mark:before {
  content: "\e606"; }

.icon-success:before {
  content: "\e607"; }

/* Scaffholding */
html, body {
  height: 100%; }

body {
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  body.page-homepage, body.page-work, body.page-studio {
    padding-top: 120px; }
    @media (max-width: 768px) {
      body.page-homepage, body.page-work, body.page-studio {
        padding-top: 60px; } }
  body.page-homepage {
    max-width: 150rem;
    margin: 0 auto; }

.body-black {
  background-color: black;
  color: white; }

.container, .container-fluid {
  padding-left: 20px;
  padding-right: 20px; }

/* Header */
header {
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }
  header div a {
    margin: 0; }
  header .header-contact a {
    color: black;
    text-decoration: none; }
  header .header-contact .social {
    float: left;
    margin-top: 12px; }
  header .header-contact .address {
    padding-left: 42px;
    padding-top: 6px; }
    header .header-contact .address p {
      margin: 0;
      line-height: 18px; }
    header .header-contact .address a {
      font-size: 13px;
      line-height: 18px;
      font-weight: normal; }
  header .header-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center; }
    header .header-nav a:first-child {
      margin-right: 1.75vw; }
      @media (max-width: 1400px) {
        header .header-nav a:first-child {
          margin-right: 1.75vw; } }
      @media (max-width: 768px) {
        header .header-nav a:first-child {
          margin-right: 1.75vw; } }
      @media (max-width: 420px) {
        header .header-nav a:first-child {
          margin-right: 2.8315vw; } }
  header #back-button {
    font-size: 30px;
    color: black;
    float: left;
    display: block;
    margin-top: 3px;
    margin-right: 0;
    position: absolute;
    top: 7px; }
    header #back-button:focus, header #back-button:active, header #back-button:visited, header #back-button:hover {
      color: inherit;
      text-decoration: none; }
    header #back-button:hover {
      opacity: 0.7; }

.social-address {
  font-size: 13px;
  line-height: 1.69231;
  font-weight: normal;
  text-align: center;
  padding-bottom: 20px; }
  .social-address p {
    margin-bottom: 0; }
  .social-address .social-fb {
    width: 30px;
    height: 30px;
    display: inline-block;
    background-color: white;
    border-radius: 50%;
    text-align: center;
    padding-top: 3px;
    margin-top: 10px;
    margin-bottom: 20px; }
    .social-address .social-fb img {
      width: 8px; }
  .social-address a,
  .social-address a:hover,
  .social-address a:focus,
  .social-address a:active,
  .social-address a:visited {
    color: inherit;
    text-decoration: none; }

.project-title {
  text-transform: uppercase;
  font-size: 19px;
  line-height: 1;
  font-weight: normal;
  height: 60px;
  display: table;
  text-align: left;
  float: left;
  margin-right: 30px;
  width: 63%; }
  .project-title .breadcrumb {
    height: 100%;
    display: table-cell;
    vertical-align: middle;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }
    .project-title .breadcrumb li {
      display: inline-block;
      margin-right: 8px;
      cursor: default; }
      .project-title .breadcrumb li:after {
        content: '/';
        margin-left: 10px;
        display: inline-block; }
      .project-title .breadcrumb li:last-child {
        margin-right: 0; }
        .project-title .breadcrumb li:last-child:after {
          content: ''; }
      .project-title .breadcrumb li strong {
        font-weight: normal; }
      .project-title .breadcrumb li a {
        color: inherit;
        text-decoration: none; }
        .project-title .breadcrumb li a:hover {
          text-decoration: underline; }

/* Menu / Home */
nav ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  text-align: center; }
  nav ul li {
    margin-top: 60px;
    margin-bottom: 60px;
    position: relative;
    z-index: 1;
    cursor: default; }
    nav ul li .nav-item {
      display: inline-block; }
    nav ul li h3 {
      margin-top: 0;
      margin-bottom: 0px; }
    nav ul li a {
      color: inherit;
      text-decoration: none; }
      nav ul li a:hover, nav ul li a:focus, nav ul li a:active {
        text-decoration: none;
        color: inherit; }
    nav ul li p {
      margin-bottom: 0;
      line-height: 25px; }

nav.nav-hidden {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0; }
  nav.nav-hidden li {
    opacity: 0;
    z-index: 3; }

.section {
  z-index: 2;
  position: relative; }

.section-home_old {
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  height: 100%;
  overflow: auto;
  padding-top: 60px;
  background-color: red;
  color: white;
  z-index: 9;
  visibility: hidden;
  display: none;
  -webkit-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  -o-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s; }
  .section-home_old.animated {
    display: block;
    visibility: visible; }
  .section-home_old nav ul {
    padding: 20px 0; }
    .section-home_old nav ul li {
      text-transform: uppercase; }
      .section-home_old nav ul li h3 {
        margin-bottom: 0;
        font-size: 45px;
        line-height: 1.2; }
  @media (min-width: 768px) {
    .section-home_old nav.nav-desktop {
      min-height: 538px; } }
  .section-home_old nav ul {
    position: absolute;
    left: 0;
    right: 0; }
    .section-home_old nav ul:not(.bottom-menu) {
      top: 50%;
      -webkit-transform: translateY(-55%);
      -moz-transform: translateY(-55%);
      transform: translateY(-55%); }
    .section-home_old nav ul li {
      display: table;
      margin: 0 auto; }
      .section-home_old nav ul li .nav-item h3 {
        font-size: 54px;
        line-height: 1.2037; }
      .section-home_old nav ul li .nav-item p {
        display: none; }
      .section-home_old nav ul li[data-position] .nav-item {
        padding: 44px 0; }
    .section-home_old nav ul.bottom-menu {
      bottom: 14px; }
      .section-home_old nav ul.bottom-menu li {
        display: inline-block;
        margin: 0 30px; }
        .section-home_old nav ul.bottom-menu li a {
          font-size: 35px;
          line-height: 1.22857; }
          .section-home_old nav ul.bottom-menu li a:hover {
            color: #6e6e6e; }
  .section-home_old .news-item {
    position: absolute;
    top: 46%;
    right: calc(50% - 330px); }
  @media (min-width: 768px) {
    .section-home_old.slideInDown + .project-detail .questionmark {
      display: none; } }

.section-archive nav {
  margin-bottom: 60px; }
  .section-archive nav h3 {
    margin-top: 0; }
  .section-archive nav ul li {
    text-align: left;
    margin: 0;
    margin-bottom: 5px; }
    .section-archive nav ul li .nav-item {
      display: block; }

.image {
  position: fixed;
  top: calc(6.15vw + 20px);
  right: 20px;
  display: table;
  height: calc(100% - 60px);
  z-index: 1;
  pointer-events: none; }
  .image figure {
    display: table-cell;
    width: 100%;
    height: 100%;
    padding: 0; }
    .image figure img {
      position: relative;
      z-index: 2;
      height: auto;
      width: auto; }
  .image.landscape figure img {
    width: auto;
    max-height: 21vw; }
  .image.portrait figure img {
    max-width: 21vw;
    height: auto; }
  .image.top-left figure {
    vertical-align: top;
    text-align: left; }
  .image.top-right figure {
    vertical-align: top;
    text-align: right; }
  .image.bottom-left figure {
    vertical-align: bottom;
    text-align: left; }
  .image.bottom-right figure {
    vertical-align: bottom;
    text-align: right; }

.page-archive .image.top-left figure, .page-archive .image.top-right figure, .page-archive .image.bottom-left figure, .page-archive .image.bottom-right figure {
  opacity: .6;
  vertical-align: middle;
  text-align: right; }

.page-home .section-home {
  visibility: visible !important;
  display: block !important; }

.page-home .hamburger {
  display: none; }

/* Templates */
.template-list {
  padding-top: 60px;
  padding-bottom: 60px; }
  .template-list h3 {
    margin-bottom: 60px; }
  .template-list ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }
    .template-list ul .icon {
      display: block;
      width: 13px;
      height: 9px;
      background-image: url(../img/icon-check.png);
      background-image: url(../img/icon-check.svg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100%;
      float: left;
      margin-top: 11px; }
    .template-list ul li {
      padding-right: 10px; }
      .template-list ul li h6 {
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 27px; }

.template-columns figure {
  position: relative; }
  .template-columns figure img {
    width: 100%;
    display: block; }
  .template-columns figure figcaption {
    background-color: #fff;
    font-size: 17px;
    line-height: 1.05882;
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 75%;
    display: inline-block;
    padding: 10px 14px; }
    .template-columns figure figcaption p {
      margin: 0; }

.template-columns h3 {
  margin-top: 60px;
  margin-bottom: 60px; }

.template-columns p {
  margin-bottom: 60px;
  margin-top: 60px; }

@media (min-width: 768px) {
  .template-columns .vertical-text {
    display: table;
    height: 50vw; }
    .template-columns .vertical-text article {
      display: table-cell;
      vertical-align: middle; } }

.template-paragraph {
  padding-top: 60px;
  padding-bottom: 60px; }
  .template-paragraph iframe {
    width: 100%;
    height: 54vw;
    max-height: 550px; }
  .template-paragraph .title {
    margin-bottom: 30px; }

.template-simple {
  padding-bottom: 0; }
  .template-simple.template-paragraph {
    max-width: 150rem;
    margin-right: auto;
    margin-left: auto; }
    .template-simple.template-paragraph h3 {
      line-height: 1.15;
      font-size: 5vw; }
      @media (max-width: 1400px) {
        .template-simple.template-paragraph h3 {
          font-size: 5vw; } }
      @media (max-width: 768px) {
        .template-simple.template-paragraph h3 {
          font-size: 5vw; } }
      @media (max-width: 420px) {
        .template-simple.template-paragraph h3 {
          font-size: 8.09vw; } }
    .template-simple.template-paragraph:nth-child(0) {
      line-height: 1.15;
      font-size: 5vw; }
      @media (max-width: 1400px) {
        .template-simple.template-paragraph:nth-child(0) {
          font-size: 5vw; } }
      @media (max-width: 768px) {
        .template-simple.template-paragraph:nth-child(0) {
          font-size: 5vw; } }
      @media (max-width: 420px) {
        .template-simple.template-paragraph:nth-child(0) {
          font-size: 8.09vw; } }

.template-people {
  padding-top: 60px;
  padding-right: 0;
  padding-left: 0; }
  .template-people .row {
    padding-right: 0;
    padding-left: 0; }
  .template-people .title {
    text-align: center; }
  .template-people .person-item {
    margin-bottom: 35px; }
    .template-people .person-item figure img {
      width: 100%;
      height: auto; }
    .template-people .person-item figure figcaption {
      text-align: center;
      padding: 10px 5px 0; }
      .template-people .person-item figure figcaption h4, .template-people .person-item figure figcaption {
        font-size: 21px;
        line-height: 1.14286;
        margin-bottom: 0;
        margin-top: 0; }
      .template-people .person-item figure figcaption a {
        color: inherit; }

.template-quote blockquote {
  display: block;
  text-align: center; }

.template-quote h1, .template-quote p {
  margin: 0; }

.title {
  margin-top: 0;
  margin-bottom: 60px; }

#map {
  height: 50vw;
  max-height: 500px; }

.template-video .video iframe {
  width: 100%;
  height: 56.2vw;
  display: block; }

.template-list-new {
  background-color: #000;
  color: #fff;
  text-align: center;
  padding-top: 60px;
  padding-bottom: 60px; }
  .template-list-new h2 {
    font-size: 60px;
    line-height: 0.95;
    margin-bottom: 60px; }
  .template-list-new ul {
    padding-left: 0;
    margin-bottom: 0;
    max-width: 746px;
    margin-right: auto;
    margin-left: auto; }
    .template-list-new ul li {
      list-style-type: none;
      margin-bottom: 20px; }
      .template-list-new ul li a {
        font-size: 32px;
        line-height: 1.15625;
        color: inherit;
        text-decoration: none;
        display: inline-block; }
        .template-list-new ul li a:hover {
          color: #6E6E6E; }

.template-maps {
  padding: 0 !important; }
  .template-maps .row {
    margin: 0; }
  @media (max-width: 768px) {
    .template-maps {
      padding: 0; } }

div[title~=Lennep] img,
img[src="https://vanlennep.eu/wp-content/themes/vanlennep-2018/assets/img/map-pin.png"] {
  transform-origin: bottom center;
  transform: scale(0.5); }
  @media (max-width: 767px) {
    div[title~=Lennep] img,
    img[src="https://vanlennep.eu/wp-content/themes/vanlennep-2018/assets/img/map-pin.png"] {
      transform: scale(0.3); } }

/* Sliders */
.fullpage-slider {
  position: relative; }
  .fullpage-slider .bx-controls .bx-pager {
    text-align: center;
    position: absolute;
    bottom: 60px;
    left: 0;
    right: 0; }
    .fullpage-slider .bx-controls .bx-pager .bx-pager-item {
      width: 24px;
      height: 24px;
      display: inline-block;
      margin: 0 15px;
      border-radius: 50%; }
      .fullpage-slider .bx-controls .bx-pager .bx-pager-item a {
        background-color: transparent;
        overflow: hidden;
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 50%;
        text-indent: 9999px;
        color: transparent;
        border: 2px solid white; }
        .fullpage-slider .bx-controls .bx-pager .bx-pager-item a.active {
          background-color: white; }

.project-slider {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .project-slider .video-cover {
    padding-left: 0;
    padding-right: 0; }
    .project-slider .video-cover iframe {
      display: block;
      width: 100%;
      height: 100%; }
  .project-slider .project {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    .project-slider .project .controls-project {
      position: absolute;
      opacity: 0;
      height: 25%;
      width: 100%;
      z-index: 3; }
    .project-slider .project .slider .col-sm-12.image-cover {
      padding: 0; }
    .project-slider .project .prev-project {
      top: 0; }
    .project-slider .project .next-project {
      bottom: 0; }
  .project-slider .questionmark {
    padding: 5px 0;
    width: 40px;
    height: 40px;
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 22;
    transition: all 180ms linear;
    background-image: url("../img/icons/plus.svg");
    background-repeat: no-repeat;
    background-position: center center; }
    @media (max-width: 768px) {
      .project-slider .questionmark {
        width: 20px;
        height: 20px; } }
    .project-slider .questionmark:hover {
      border-color: white; }
    .project-slider .questionmark.active {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      transform: rotate(45deg); }

.no-touch .project-slider .bx-controls-direction a,
.no-touch .fullpage-slider .bx-controls-direction a {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 25%;
  z-index: 2; }
  .no-touch .project-slider .bx-controls-direction a:focus, .no-touch .project-slider .bx-controls-direction a:active, .no-touch .project-slider .bx-controls-direction a:visited,
  .no-touch .fullpage-slider .bx-controls-direction a:focus,
  .no-touch .fullpage-slider .bx-controls-direction a:active,
  .no-touch .fullpage-slider .bx-controls-direction a:visited {
    text-decoration: none;
    color: white; }
  .no-touch .project-slider .bx-controls-direction a:hover,
  .no-touch .fullpage-slider .bx-controls-direction a:hover {
    color: white;
    text-decoration: none; }
  .no-touch .project-slider .bx-controls-direction a.bx-prev,
  .no-touch .fullpage-slider .bx-controls-direction a.bx-prev {
    left: 0; }
  .no-touch .project-slider .bx-controls-direction a.bx-next,
  .no-touch .fullpage-slider .bx-controls-direction a.bx-next {
    right: 0; }
  .no-touch .project-slider .bx-controls-direction a.disabled,
  .no-touch .fullpage-slider .bx-controls-direction a.disabled {
    display: none; }

.touch .project-slider .project .controls-project {
  display: none !important; }

.touch .project-slider .bx-controls a,
.touch .fullpage-slider .bx-controls a {
  position: absolute;
  top: 23px;
  bottom: 60px;
  z-index: 2;
  width: 58px;
  opacity: 1;
  background-repeat: no-repeat;
  cursor: pointer; }
  .touch .project-slider .bx-controls a.bx-prev,
  .touch .fullpage-slider .bx-controls a.bx-prev {
    left: 10px;
    background-image: url(../img/icons/arrow-left.svg);
    background-position: right center; }
    @media (max-width: 767px) {
      .touch .project-slider .bx-controls a.bx-prev,
      .touch .fullpage-slider .bx-controls a.bx-prev {
        left: 0; } }
  .touch .project-slider .bx-controls a.disabled,
  .touch .fullpage-slider .bx-controls a.disabled {
    display: none; }
  .touch .project-slider .bx-controls a.bx-next,
  .touch .fullpage-slider .bx-controls a.bx-next {
    right: 10px;
    background-image: url(../img/icons/arrow-right.svg);
    background-position: left center; }
    @media (max-width: 767px) {
      .touch .project-slider .bx-controls a.bx-next,
      .touch .fullpage-slider .bx-controls a.bx-next {
        right: 0; } }

/* Carousel */
.slick-slider .slick-list {
  height: calc(100vh - 60px) !important; }

.slick-track {
  top: 50%;
  margin-top: -130px; }

/* Projectpage */
.project-detail .full-height {
  height: 100vh; }

.project-detail .caption-holder {
  background-color: #fff;
  color: #000;
  position: absolute;
  top: 0px;
  left: -50%;
  height: 100%;
  overflow: auto;
  z-index: 5;
  padding-top: 8vw;
  transition: left 800ms cubic-bezier(0.23, 1, 0.32, 1); }
  .project-detail .caption-holder .title {
    margin-bottom: 20px;
    text-transform: uppercase; }
    @media (max-width: 420px) {
      .project-detail .caption-holder .title {
        margin-bottom: 8px; } }
  .project-detail .caption-holder.animated {
    left: 0; }
  .project-detail .caption-holder .caption {
    height: 100%; }
  @media (max-width: 768px) {
    .project-detail .caption-holder {
      left: -100%; }
      .project-detail .caption-holder.animated {
        left: 0; }
      .project-detail .caption-holder article .contributors {
        margin-top: 15px; } }
  @media (max-width: 420px) {
    .project-detail .caption-holder {
      padding-top: 15vw; } }

.project-detail .title {
  line-height: 1.15;
  font-size: 3.5vw;
  margin-bottom: 3.5vw; }
  @media (max-width: 1400px) {
    .project-detail .title {
      font-size: 3.5vw;
      margin-bottom: 3.5vw; } }
  @media (max-width: 768px) {
    .project-detail .title {
      font-size: 3.5vw;
      margin-bottom: 3.5vw; } }
  @media (max-width: 420px) {
    .project-detail .title {
      font-size: 5.663vw;
      margin-bottom: 5.663vw; } }

.project-detail article {
  line-height: 1.15;
  font-size: 2.5vw;
  margin-bottom: 2.5vw; }
  @media (max-width: 1400px) {
    .project-detail article {
      font-size: 2.5vw;
      margin-bottom: 2.5vw; } }
  @media (max-width: 768px) {
    .project-detail article {
      font-size: 2.5vw;
      margin-bottom: 2.5vw; } }
  @media (max-width: 420px) {
    .project-detail article {
      font-size: 4.045vw;
      margin-bottom: 4.045vw; } }
  .project-detail article p a {
    color: #0000FF;
    text-decoration: none; }
    .project-detail article p a:hover {
      border-bottom: 2px solid; }
  .project-detail article ul.list {
    padding-left: 0; }
    .project-detail article ul.list li {
      list-style: none; }
      .project-detail article ul.list li:before {
        margin-right: 15px; }
  .project-detail article .contributors {
    line-height: 1.15;
    font-size: 2.5vw;
    margin-bottom: 2.5vw;
    margin-top: 25px; }
    @media (max-width: 1400px) {
      .project-detail article .contributors {
        font-size: 2.5vw;
        margin-bottom: 2.5vw; } }
    @media (max-width: 768px) {
      .project-detail article .contributors {
        font-size: 2.5vw;
        margin-bottom: 2.5vw; } }
    @media (max-width: 420px) {
      .project-detail article .contributors {
        font-size: 4.045vw;
        margin-bottom: 4.045vw; } }
    .project-detail article .contributors ul {
      display: inline-block;
      margin-top: 0;
      padding-left: 0; }
      .project-detail article .contributors ul li {
        display: inline;
        list-style-type: none; }
        .project-detail article .contributors ul li a {
          border-bottom: 1px solid #000;
          color: inherit; }
          .project-detail article .contributors ul li a:hover, .project-detail article .contributors ul li a:focus, .project-detail article .contributors ul li a:active {
            text-decoration: none; }
          .project-detail article .contributors ul li a:hover {
            color: #6E6E6E;
            border-color: #6E6E6E; }
        .project-detail article .contributors ul li:not(:last-child)::after {
          content: ',';
          display: inline-block; }
  @media (max-width: 420px) {
    .project-detail article {
      font-size: 22px;
      margin-bottom: 4.045vw;
      line-height: 1.23;
      letter-spacing: -0.5px; } }

@media (max-width: 767px) {
  .project-detail .project-slider.full-height {
    overflow: visible;
    height: 56.25vw;
    margin-top: 60px; }
  .project-detail .project .bx-wrapper,
  .project-detail .project .bx-viewport {
    height: 56.25vw !important; }
  .project-detail .caption-holder {
    height: auto;
    min-height: 100vh;
    padding-top: 0; }
  .project-detail .info {
    position: fixed;
    line-height: 1.7;
    font-size: 21px;
    bottom: 10px;
    left: 40px; }
  .project-detail .next-project-mobile {
    position: fixed;
    line-height: 1.7;
    font-size: 21px;
    bottom: 10px;
    right: 20px; }
  .project-detail .mobile-caption-holder {
    position: relative;
    height: auto;
    left: 0 !important;
    margin-top: 80px;
    padding-top: 0; }
  .project-detail .image-cover.full-height, .project-detail .video-cover.full-height {
    height: 56.25vw !important; }
  .project-detail .bx-controls.bx-has-controls-direction {
    transform: translateY(0px);
    height: 120px; } }

.project .overlay {
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 1; }

/* Helpers */
.full-height {
  position: relative;
  height: calc(100vh - 60px); }

.vertical {
  display: table-cell;
  vertical-align: middle; }

.image-cover {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat; }

.col {
  padding-left: 0 !important;
  padding-right: 0 !important; }

/* Elements */
blockquote {
  padding: 0;
  margin: 0;
  font-size: inherit;
  border-left: 0; }

.two-column {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 60px;
  -moz-column-gap: 60px;
  column-gap: 60px; }

/* Buttons */
.btn-circle {
  width: 24px;
  height: 24px;
  display: block;
  background-color: transparent;
  border: 2px solid white;
  color: white;
  font-size: 8px;
  text-align: center;
  border-radius: 50%;
  line-height: 1;
  padding: 6px 0; }
  .btn-circle:focus, .btn-circle:active, .btn-circle:visited {
    text-decoration: none;
    color: white; }
  .btn-circle:hover {
    background-color: black;
    color: white;
    text-decoration: none; }

/* Hamburger */
.hamburger {
  width: 34px;
  display: block;
  float: right;
  margin-top: 22px; }
  .hamburger .line {
    display: block;
    height: 2px;
    background-color: black;
    margin-bottom: 11px;
    -webkit-transition: -webkit-transform .35s linear;
    -moz-transition: -moz-transform .35s linear;
    transition: transform .35s linear; }
    .hamburger .line:last-child {
      margin-bottom: 0; }
  .hamburger.active .line:first-child {
    -webkit-transform: rotate(45deg) translate(5px, 5px);
    -moz-transform: rotate(45deg) translate(5px, 5px);
    transform: rotate(45deg) translate(5px, 5px); }
  .hamburger.active .line:last-child {
    -webkit-transform: rotate(-45deg) translate(5px, -4px);
    -moz-transform: rotate(-45deg) translate(5px, -4px);
    transform: rotate(-45deg) translate(5px, -4px); }

#next-section {
  position: fixed;
  bottom: 30px;
  left: 50%;
  margin-left: -31px;
  z-index: 3;
  display: none; }

.headroom {
  will-change: transform;
  transition: transform 200ms linear;
  -webkit-transition: -webkit-transform 200ms linear; }

.headroom--pinned {
  transform: translateY(0%);
  -webkit-transform: -webkit-translateY(0%); }

.headroom--unpinned {
  transform: translateY(-100%);
  -webkit-transform: -webkit-translateY(-100%); }

/* 2018 Changes */
section.section-home_alt {
  background-color: #fff; }

.home--header .header--video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%; }
  .home--header .header--video iframe, .home--header .header--video embed, .home--header .header--video object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .home--header .header--video:after, .home--header .header--video:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: all;
    background: rgba(255, 255, 255, 0); }
  .home--header .header--video:before {
    background: white;
    z-index: 2; }

.home--header .header--nav ul {
  display: flex;
  justify-content: center;
  align-items: center; }

.home--intro {
  margin-bottom: 10%;
  margin-top: 5%; }

/* Grid */
.people-grid {
  display: block;
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 30px;
  max-width: 150rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row; }
  @media (max-width: 768px) {
    .people-grid {
      grid-template-columns: 1fr 1fr; } }
  @media (max-width: 420px) {
    .people-grid {
      grid-template-columns: 1fr;
      grid-auto-rows: 80vh; } }
  @media (max-width: 768px) {
    .people-grid {
      grid-gap: 20px; } }
  @media (max-width: 1400px) {
    .people-grid {
      grid-gap: 30px; } }

.people-grid--item {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-bottom: 30px;
  margin-right: 60px;
  width: calc(100% / 3 - (30px + 20px)); }
  .people-grid--item:nth-child(3n) {
    margin-right: 0; }
  @media (max-width: 768px) {
    .people-grid--item {
      width: calc(100% / 2 - (20px));
      margin-right: 30px; }
      .people-grid--item:nth-child(3n) {
        margin-right: 30px; }
      .people-grid--item:nth-child(2n) {
        margin-right: 0; } }
  @media (max-width: 420px) {
    .people-grid--item {
      width: 100%;
      margin-right: 0; }
      .people-grid--item:nth-child(2n), .people-grid--item:nth-child(3n) {
        margin-right: 0; } }
  .people-grid--item h3 {
    color: #fff;
    position: relative;
    line-height: 1.15;
    font-size: 3.5vw;
    margin-bottom: 3.5vw; }
    @media (max-width: 1400px) {
      .people-grid--item h3 {
        font-size: 3.5vw;
        margin-bottom: 3.5vw; } }
    @media (max-width: 768px) {
      .people-grid--item h3 {
        font-size: 3.5vw;
        margin-bottom: 3.5vw; } }
    @media (max-width: 420px) {
      .people-grid--item h3 {
        font-size: 5.663vw;
        margin-bottom: 5.663vw; } }
    .people-grid--item h3:after {
      content: attr(data-job);
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0; }
  .people-grid--item .item--container {
    position: relative;
    background-color: #fff;
    height: 100%; }
    .people-grid--item .item--container .item--text {
      position: relative;
      height: 100%;
      cursor: inherit;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: absolute;
      left: 0;
      top: 0;
      padding: 30px;
      z-index: 3; }
      .people-grid--item .item--container .item--text .item--text__subtitle,
      .people-grid--item .item--container .item--text .item--text__title {
        transform: rotate(0) rotateX(0) translateX(-110%) translateY(0) scale(1) translateZ(0);
        margin: 0 0 0.75rem;
        color: #000;
        z-index: 2; }
      .people-grid--item .item--container .item--text .item--text__title {
        line-height: 1.15;
        font-size: 3.5vw;
        margin-bottom: 3.5vw;
        transition: transform 920ms cubic-bezier(0.23, 1, 0.32, 1);
        font-weight: 400;
        hyphens: none; }
        @media (max-width: 1400px) {
          .people-grid--item .item--container .item--text .item--text__title {
            font-size: 3.5vw;
            margin-bottom: 3.5vw; } }
        @media (max-width: 768px) {
          .people-grid--item .item--container .item--text .item--text__title {
            font-size: 3.5vw;
            margin-bottom: 3.5vw; } }
        @media (max-width: 420px) {
          .people-grid--item .item--container .item--text .item--text__title {
            font-size: 5.663vw;
            margin-bottom: 5.663vw; } }
      .people-grid--item .item--container .item--text .item--text__subtitle {
        line-height: 1.15;
        font-size: 2.5vw;
        margin-bottom: 2.5vw;
        transition: transform 600ms cubic-bezier(0.23, 1, 0.32, 1);
        font-weight: 200;
        justify-self: flex-end;
        padding-right: 60px; }
        @media (max-width: 1400px) {
          .people-grid--item .item--container .item--text .item--text__subtitle {
            font-size: 2.5vw;
            margin-bottom: 2.5vw; } }
        @media (max-width: 768px) {
          .people-grid--item .item--container .item--text .item--text__subtitle {
            font-size: 2.5vw;
            margin-bottom: 2.5vw; } }
        @media (max-width: 420px) {
          .people-grid--item .item--container .item--text .item--text__subtitle {
            font-size: 4.045vw;
            margin-bottom: 4.045vw; } }
    .people-grid--item .item--container .people-card--image {
      z-index: 0;
      display: block;
      width: 100%;
      overflow: hidden;
      object-fit: cover; }
      .people-grid--item .item--container .people-card--image img {
        height: 100%;
        width: 100%;
        z-index: -1;
        object-fit: cover; }
      .people-grid--item .item--container .people-card--image:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: #fff;
        z-index: 2;
        transform: rotate(0) rotateX(0) translateX(-110%) translateY(0) scale(1) translateZ(0);
        transition: transform 600ms cubic-bezier(0.23, 1, 0.32, 1); }
  @media screen and (min-width: 767px) {
    .people-grid--item:hover a {
      color: #000;
      filter: none; }
    .people-grid--item:hover h3 span {
      opacity: 0; }
    .people-grid--item:hover h3:after {
      content: attr(data-job);
      opacity: 1; }
    .people-grid--item:hover .item--container .item--text__subtitle,
    .people-grid--item:hover .item--container .item--text__title {
      transform: rotate(0) rotateX(0) translateX(0) translateY(0) translateZ(0); }
    .people-grid--item:hover .item--container .item--text__subtitle {
      transition: transform 920ms cubic-bezier(0.23, 1, 0.32, 1) 125ms; }
    .people-grid--item:hover .item--container .people-card--image:before {
      transform: rotate(0) rotateX(0) translateX(0) translateY(0) translateZ(0); } }

.footer {
  display: flex;
  flex-direction: row;
  color: #000;
  text-align: left;
  padding: 0;
  max-width: 150rem;
  padding: 20px; }
  @media (max-width: 420px) {
    .footer {
      flex-direction: column;
      padding-top: 0; } }
  .footer--address, .footer--links {
    margin-bottom: 20px; }
  .footer--address a:hover {
    text-decoration: underline; }
  .footer h2 {
    margin-bottom: 60px; }
  .footer address {
    margin-bottom: 0; }
    .footer address p, .footer address a {
      margin-bottom: 0; }
  .footer ul {
    padding-left: 0;
    max-width: 746px;
    margin-right: auto;
    margin-left: auto; }
    .footer ul li {
      list-style-type: none; }

section.section-archive h2 {
  font-family: "VL_handwrite_V1.0.2-regular", "plain-medium", Arial, Helvetica, sans-serif;
  color: #000;
  text-transform: uppercase;
  font-size: 4vw;
  margin: 1vw 0; }
  @media (max-width: 768px) {
    section.section-archive h2 {
      font-size: 6vw;
      margin: 1.5vw 0; } }
  @media (max-width: 420px) {
    section.section-archive h2 {
      font-size: 6vw;
      margin: 1.5vw 0; } }

section.section-archive .highlights {
  width: calc(100% + 60px);
  margin-left: -30px;
  padding: 30px;
  padding-top: 0;
  position: relative;
  margin-bottom: 60px; }
  section.section-archive .highlights:after {
    position: absolute;
    left: 30px;
    bottom: -30px;
    content: '';
    width: calc(100% - 60px);
    height: 20px;
    background-image: url(../img/vl-line.svg);
    background-size: contain;
    background-repeat: no-repeat; }
    @media (max-width: 768px) {
      section.section-archive .highlights:after {
        background-image: url(../img/vl-line_medium.svg);
        bottom: 0; } }
    @media (max-width: 420px) {
      section.section-archive .highlights:after {
        background-image: url(../img/vl-line_small.svg); } }
  @media (max-width: 768px) {
    section.section-archive .highlights {
      margin-bottom: 0; } }

body.page-work_item {
  padding-top: 0; }
  body.page-work_item .full-height {
    height: 100vh; }

.page-studio {
  background-color: #000;
  color: #fff; }
  .page-studio .container-fluid {
    display: flex;
    flex-direction: column; }
  .page-studio footer {
    color: #fff;
    padding: 60px 20px;
    margin-bottom: 60px; }
    @media (max-width: 768px) {
      .page-studio footer {
        padding: 30px 20px;
        margin-bottom: 30px; } }
  .page-studio section.studio--people {
    margin-top: 5vw; }
  .page-studio section.studio--map {
    margin-top: 5vw;
    width: calc(100% + 40px);
    margin-left: -20px; }

.logo--vl__loader h3 {
  font-family: "plain-medium", Arial, Helvetica, sans-serif;
  line-height: 1.15;
  font-size: 3.5vw;
  margin-bottom: 3.5vw;
  text-transform: none;
  margin-bottom: 2vw !important; }
  @media (max-width: 1400px) {
    .logo--vl__loader h3 {
      font-size: 3.5vw;
      margin-bottom: 3.5vw; } }
  @media (max-width: 768px) {
    .logo--vl__loader h3 {
      font-size: 3.5vw;
      margin-bottom: 3.5vw; } }
  @media (max-width: 420px) {
    .logo--vl__loader h3 {
      font-size: 5.663vw;
      margin-bottom: 5.663vw; } }
  @media (max-width: 420px) {
    .logo--vl__loader h3 {
      margin-bottom: 3vw !important; } }

.logo--vl__loader .payoff--items {
  display: none;
  visibility: hidden; }

.logo--vl__loader h4.chosenPayoff {
  font-family: "VL_handwrite_V1.0.2-regular", "plain-medium", Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  line-height: 1.15;
  font-size: 5vw;
  margin: 0; }
  @media (max-width: 1400px) {
    .logo--vl__loader h4.chosenPayoff {
      font-size: 5vw; } }
  @media (max-width: 768px) {
    .logo--vl__loader h4.chosenPayoff {
      font-size: 5vw; } }
  @media (max-width: 420px) {
    .logo--vl__loader h4.chosenPayoff {
      font-size: 8.09vw; } }
  .logo--vl__loader h4.chosenPayoff.animated {
    animation: hideAndSeek 220ms linear forwards;
    visibility: hidden;
    background-color: #000;
    width: 100%; }
    .logo--vl__loader h4.chosenPayoff.animated:nth-child(1) {
      animation-delay: 220ms; }
    .logo--vl__loader h4.chosenPayoff.animated:first-child {
      z-index: 1; }
    .logo--vl__loader h4.chosenPayoff.animated:last-child {
      animation: stayThere 220ms linear forwards;
      z-index: -1; }
    .logo--vl__loader h4.chosenPayoff.animated:nth-child(2) {
      animation-delay: 440ms; }
    .logo--vl__loader h4.chosenPayoff.animated:first-child {
      z-index: 2; }
    .logo--vl__loader h4.chosenPayoff.animated:last-child {
      animation: stayThere 220ms linear forwards;
      z-index: -2; }
    .logo--vl__loader h4.chosenPayoff.animated:nth-child(3) {
      animation-delay: 660ms; }
    .logo--vl__loader h4.chosenPayoff.animated:first-child {
      z-index: 3; }
    .logo--vl__loader h4.chosenPayoff.animated:last-child {
      animation: stayThere 220ms linear forwards;
      z-index: -3; }
    .logo--vl__loader h4.chosenPayoff.animated:nth-child(4) {
      animation-delay: 880ms; }
    .logo--vl__loader h4.chosenPayoff.animated:first-child {
      z-index: 4; }
    .logo--vl__loader h4.chosenPayoff.animated:last-child {
      animation: stayThere 220ms linear forwards;
      z-index: -4; }
    .logo--vl__loader h4.chosenPayoff.animated:nth-child(5) {
      animation-delay: 1100ms; }
    .logo--vl__loader h4.chosenPayoff.animated:first-child {
      z-index: 5; }
    .logo--vl__loader h4.chosenPayoff.animated:last-child {
      animation: stayThere 220ms linear forwards;
      z-index: -5; }
    .logo--vl__loader h4.chosenPayoff.animated:nth-child(6) {
      animation-delay: 1320ms; }
    .logo--vl__loader h4.chosenPayoff.animated:first-child {
      z-index: 6; }
    .logo--vl__loader h4.chosenPayoff.animated:last-child {
      animation: stayThere 220ms linear forwards;
      z-index: -6; }
    .logo--vl__loader h4.chosenPayoff.animated:nth-child(7) {
      animation-delay: 1540ms; }
    .logo--vl__loader h4.chosenPayoff.animated:first-child {
      z-index: 7; }
    .logo--vl__loader h4.chosenPayoff.animated:last-child {
      animation: stayThere 220ms linear forwards;
      z-index: -7; }
    .logo--vl__loader h4.chosenPayoff.animated:nth-child(8) {
      animation-delay: 1760ms; }
    .logo--vl__loader h4.chosenPayoff.animated:first-child {
      z-index: 8; }
    .logo--vl__loader h4.chosenPayoff.animated:last-child {
      animation: stayThere 220ms linear forwards;
      z-index: -8; }
    .logo--vl__loader h4.chosenPayoff.animated:nth-child(9) {
      animation-delay: 1980ms; }
    .logo--vl__loader h4.chosenPayoff.animated:first-child {
      z-index: 9; }
    .logo--vl__loader h4.chosenPayoff.animated:last-child {
      animation: stayThere 220ms linear forwards;
      z-index: -9; }
    .logo--vl__loader h4.chosenPayoff.animated:nth-child(10) {
      animation-delay: 2200ms; }
    .logo--vl__loader h4.chosenPayoff.animated:first-child {
      z-index: 10; }
    .logo--vl__loader h4.chosenPayoff.animated:last-child {
      animation: stayThere 220ms linear forwards;
      z-index: -10; }
    .logo--vl__loader h4.chosenPayoff.animated:nth-child(11) {
      animation-delay: 2420ms; }
    .logo--vl__loader h4.chosenPayoff.animated:first-child {
      z-index: 11; }
    .logo--vl__loader h4.chosenPayoff.animated:last-child {
      animation: stayThere 220ms linear forwards;
      z-index: -11; }
    .logo--vl__loader h4.chosenPayoff.animated:nth-child(12) {
      animation-delay: 2640ms; }
    .logo--vl__loader h4.chosenPayoff.animated:first-child {
      z-index: 12; }
    .logo--vl__loader h4.chosenPayoff.animated:last-child {
      animation: stayThere 220ms linear forwards;
      z-index: -12; }
    .logo--vl__loader h4.chosenPayoff.animated:nth-child(13) {
      animation-delay: 2860ms; }
    .logo--vl__loader h4.chosenPayoff.animated:first-child {
      z-index: 13; }
    .logo--vl__loader h4.chosenPayoff.animated:last-child {
      animation: stayThere 220ms linear forwards;
      z-index: -13; }
    .logo--vl__loader h4.chosenPayoff.animated:nth-child(14) {
      animation-delay: 3080ms; }
    .logo--vl__loader h4.chosenPayoff.animated:first-child {
      z-index: 14; }
    .logo--vl__loader h4.chosenPayoff.animated:last-child {
      animation: stayThere 220ms linear forwards;
      z-index: -14; }
    .logo--vl__loader h4.chosenPayoff.animated:nth-child(15) {
      animation-delay: 3300ms; }
    .logo--vl__loader h4.chosenPayoff.animated:first-child {
      z-index: 15; }
    .logo--vl__loader h4.chosenPayoff.animated:last-child {
      animation: stayThere 220ms linear forwards;
      z-index: -15; }
    .logo--vl__loader h4.chosenPayoff.animated:nth-child(16) {
      animation-delay: 3520ms; }
    .logo--vl__loader h4.chosenPayoff.animated:first-child {
      z-index: 16; }
    .logo--vl__loader h4.chosenPayoff.animated:last-child {
      animation: stayThere 220ms linear forwards;
      z-index: -16; }
    .logo--vl__loader h4.chosenPayoff.animated:nth-child(17) {
      animation-delay: 3740ms; }
    .logo--vl__loader h4.chosenPayoff.animated:first-child {
      z-index: 17; }
    .logo--vl__loader h4.chosenPayoff.animated:last-child {
      animation: stayThere 220ms linear forwards;
      z-index: -17; }
    .logo--vl__loader h4.chosenPayoff.animated:nth-child(18) {
      animation-delay: 3960ms; }
    .logo--vl__loader h4.chosenPayoff.animated:first-child {
      z-index: 18; }
    .logo--vl__loader h4.chosenPayoff.animated:last-child {
      animation: stayThere 220ms linear forwards;
      z-index: -18; }
    .logo--vl__loader h4.chosenPayoff.animated:nth-child(19) {
      animation-delay: 4180ms; }
    .logo--vl__loader h4.chosenPayoff.animated:first-child {
      z-index: 19; }
    .logo--vl__loader h4.chosenPayoff.animated:last-child {
      animation: stayThere 220ms linear forwards;
      z-index: -19; }
    .logo--vl__loader h4.chosenPayoff.animated:nth-child(20) {
      animation-delay: 4400ms; }
    .logo--vl__loader h4.chosenPayoff.animated:first-child {
      z-index: 20; }
    .logo--vl__loader h4.chosenPayoff.animated:last-child {
      animation: stayThere 220ms linear forwards;
      z-index: -20; }

.logo--vl__header {
  font-family: "plain-medium", Arial, Helvetica, sans-serif;
  letter-spacing: 0;
  margin: 0;
  line-height: 1.15;
  font-size: 3.5vw;
  margin-bottom: 3.5vw; }
  @media (max-width: 1400px) {
    .logo--vl__header {
      font-size: 3.5vw;
      margin-bottom: 3.5vw; } }
  @media (max-width: 768px) {
    .logo--vl__header {
      font-size: 3.5vw;
      margin-bottom: 3.5vw; } }
  @media (max-width: 420px) {
    .logo--vl__header {
      font-size: 5.663vw;
      margin-bottom: 5.663vw; } }
  .logo--vl__header__light {
    color: #fff; }

@keyframes hideAndSeek {
  0% {
    visibility: visible; }
  100% {
    visibility: hidden; } }

@keyframes stayThere {
  50% {
    visibility: hidden; }
  100% {
    visibility: visible; } }

/* Loader */
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: #000;
  color: #fff;
  text-align: center; }
  .loader .mission-statement {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    display: table;
    width: 100vw; }
    .loader .mission-statement .ms-content {
      display: table-cell;
      vertical-align: middle;
      width: 100%;
      height: 100%; }
      .loader .mission-statement .ms-content img {
        margin-bottom: 60px;
        max-width: 90%; }
      .loader .mission-statement .ms-content h1 {
        text-transform: uppercase;
        margin-bottom: 0; }
      .loader .mission-statement .ms-content h3 {
        font-weight: normal;
        margin: 0; }

.loader {
  overflow: hidden;
  transform: translateY(0);
  transition: transform 920ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  z-index: 11; }
  .loader .mission-statement {
    transform: translateY(0);
    transition: transform 800ms cubic-bezier(0.23, 1, 0.32, 1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left; }
  .loader.vl-slideUp {
    transform: translateY(-100%); }
    .loader.vl-slideUp .mission-statement {
      transform: translateY(-75%); }

body .header--video:before {
  transform: translateY(-100%); }

body {
  font-family: "plain-medium", Arial, Helvetica, sans-serif;
  text-rendering: geometricPrecision;
  overflow-x: hidden; }

::selection {
  color: #fff;
  background: #000; }

.page-studio ::selection {
  color: #000;
  background: #fff; }

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-variant-ligatures: discretionary-ligatures;
  text-align: left;
  hyphens: none; }

h1,
.h1 {
  font-size: 60px;
  line-height: 1.2; }

h2,
.h2 {
  font-size: 53px; }

h3,
.h3 {
  font-size: 45px;
  line-height: 1.2; }

h4,
.h4 {
  font-size: 29px; }

h5,
.h5 {
  font-size: 28px; }

h6,
.h6 {
  font-size: 25px; }

.page-homepage p, .page-homepage a,
footer p,
footer a {
  line-height: 1.15;
  font-size: 5vw; }
  @media (max-width: 1400px) {
    .page-homepage p, .page-homepage a,
    footer p,
    footer a {
      font-size: 5vw; } }
  @media (max-width: 768px) {
    .page-homepage p, .page-homepage a,
    footer p,
    footer a {
      font-size: 5vw; } }
  @media (max-width: 420px) {
    .page-homepage p, .page-homepage a,
    footer p,
    footer a {
      font-size: 8.09vw; } }

.page-studio p,
.page-studio footer p,
.page-studio footer a {
  line-height: 1.15;
  font-size: 3.5vw;
  margin-bottom: 3.5vw; }
  @media (max-width: 1400px) {
    .page-studio p,
    .page-studio footer p,
    .page-studio footer a {
      font-size: 3.5vw;
      margin-bottom: 3.5vw; } }
  @media (max-width: 768px) {
    .page-studio p,
    .page-studio footer p,
    .page-studio footer a {
      font-size: 3.5vw;
      margin-bottom: 3.5vw; } }
  @media (max-width: 420px) {
    .page-studio p,
    .page-studio footer p,
    .page-studio footer a {
      font-size: 5.663vw;
      margin-bottom: 5.663vw; } }

.page-studio footer {
  max-width: 150rem;
  margin: 0 auto; }
  .page-studio footer p,
  .page-studio footer a {
    margin-bottom: 0; }

.section-archive nav ul li h3,
.section-archive nav > h3 {
  line-height: 1.15;
  font-size: 5vw;
  margin: 0; }
  @media (max-width: 1400px) {
    .section-archive nav ul li h3,
    .section-archive nav > h3 {
      font-size: 5vw; } }
  @media (max-width: 768px) {
    .section-archive nav ul li h3,
    .section-archive nav > h3 {
      font-size: 5vw; } }
  @media (max-width: 420px) {
    .section-archive nav ul li h3,
    .section-archive nav > h3 {
      font-size: 8.09vw; } }

.header-nav a {
  line-height: 1.15;
  font-size: 3.5vw;
  margin-bottom: 3.5vw; }
  @media (max-width: 1400px) {
    .header-nav a {
      font-size: 3.5vw;
      margin-bottom: 3.5vw; } }
  @media (max-width: 768px) {
    .header-nav a {
      font-size: 3.5vw;
      margin-bottom: 3.5vw; } }
  @media (max-width: 420px) {
    .header-nav a {
      font-size: 5.663vw;
      margin-bottom: 5.663vw; } }
  .header-nav a:nth-child(1) {
    margin-right: -2px; }

.cursor-on {
  cursor: none; }
  .cursor-on * {
    cursor: none; }
  .cursor-on .cursor {
    position: fixed;
    z-index: 10;
    pointer-events: none;
    top: -2rem;
    left: -2rem; }
    .cursor-on .cursor > span {
      display: block;
      width: 4rem;
      height: 4rem;
      border-radius: 4rem;
      background: #000000;
      color: #ffffff;
      transition: transform 180ms cubic-bezier(0.23, 1, 0.32, 1);
      transform: scale(1); }
      .cursor-on .cursor > span.off {
        transform: scale(0); }
      .cursor-on .cursor > span.link {
        transform: scale(1.25);
        border: 4px solid #000000;
        background: transparent;
        transition: background 180ms cubic-bezier(0.23, 1, 0.32, 1); }
        .cursor-on .cursor > span.link.external {
          border: 4px solid #000000;
          background: #000000; }
          .cursor-on .cursor > span.link.external:after {
            content: "↗";
            display: block;
            white-space: pre;
            color: #ffffff;
            font-weight: 100;
            font-size: 2rem;
            text-align: center;
            width: 100%;
            line-height: 1;
            padding-top: 4px; }
        .cursor-on .cursor > span.link.next, .cursor-on .cursor > span.link.prev, .cursor-on .cursor > span.link.next-img, .cursor-on .cursor > span.link.prev-img, .cursor-on .cursor > span.link.cross {
          background: #000000; }
        .cursor-on .cursor > span.link.next-img {
          background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA2NCA2NCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNjQgNjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiNGRkZGRkY7fQ0KPC9zdHlsZT4NCjxwb2x5Z29uIGlkPSJYTUxJRF82XyIgY2xhc3M9InN0MCIgcG9pbnRzPSIxNC41LDMwLjQgNDIuNSwzMC40IDMyLjUsMjAuNCAzNC43LDE4LjEgNDYuMiwyOS42IDQ2LjIsMjkuNiA0OC41LDMxLjggNDguNSwzMS44IA0KCTQ4LjUsMzEuOCA0Ni4yLDM0LjEgNDYuMiwzNC4xIDM0LjcsNDUuNiAzMi41LDQzLjMgNDIuMSwzMy42IDE0LjUsMzMuNiAiLz4NCjwvc3ZnPg0K) !important; }
        .cursor-on .cursor > span.link.prev-img {
          background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA2NCA2NCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNjQgNjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiNGRkZGRkY7fQ0KPC9zdHlsZT4NCjxwb2x5Z29uIGlkPSJYTUxJRF82XyIgY2xhc3M9InN0MCIgcG9pbnRzPSI0OC41LDMwLjQgMjAuNSwzMC40IDMwLjUsMjAuNCAyOC4yLDE4LjEgMTYuOCwyOS42IDE2LjgsMjkuNiAxNC41LDMxLjggMTQuNSwzMS44IA0KCTE0LjUsMzEuOCAxNi44LDM0LjEgMTYuOCwzNC4xIDI4LjIsNDUuNiAzMC41LDQzLjMgMjAuOCwzMy42IDQ4LjUsMzMuNiAiLz4NCjwvc3ZnPg0K) !important; }
        .cursor-on .cursor > span.link.next-img, .cursor-on .cursor > span.link.prev-img {
          background: #000000;
          background-size: 100%;
          background-repeat: no-repeat;
          background-position: center; }
      .cursor-on .cursor > span.light {
        background: #ffffff; }
        .cursor-on .cursor > span.light.link {
          background: transparent;
          border-color: #ffffff; }
        .cursor-on .cursor > span.light.mail {
          background: #0000ed;
          border: none; }
      .cursor-on .cursor > span.play, .cursor-on .cursor > span.click, .cursor-on .cursor > span.next, .cursor-on .cursor > span.prev, .cursor-on .cursor > span.next-img, .cursor-on .cursor > span.prev-img, .cursor-on .cursor > span.cross, .cursor-on .cursor > span.relol, .cursor-on .cursor > span.new, .cursor-on .cursor > span.mail {
        transform: scale(1.5); }
      .cursor-on .cursor > span.play:after, .cursor-on .cursor > span.click:after, .cursor-on .cursor > span.next:after, .cursor-on .cursor > span.prev:after, .cursor-on .cursor > span.next-img:after, .cursor-on .cursor > span.prev-img:after, .cursor-on .cursor > span.cross:after, .cursor-on .cursor > span.relol:after, .cursor-on .cursor > span.new:after, .cursor-on .cursor > span.mail:after {
        display: block;
        content: "";
        white-space: pre;
        font-size: 8px;
        text-align: center;
        width: 100%;
        line-height: 1;
        padding-top: calc((4rem / 2) - 4px);
        color: #ffffff; }
      .cursor-on .cursor > span.play:after, .cursor-on .cursor > span.play.pause:after {
        content: "play"; }
        .cursor-on .cursor > span.play:after:active .cursor > span, .cursor-on .cursor > span.play.pause:after:active .cursor > span {
          transform: scale(1.25); }
      .cursor-on .cursor > span.next:after, .cursor-on .cursor > span.prev:after, .cursor-on .cursor > span.next-img:after, .cursor-on .cursor > span.prev-img:after {
        padding-top: 7px; }
      .cursor-on .cursor > span.play:after {
        content: "pause"; }
      .cursor-on .cursor > span.play.pause:after {
        content: "play"; }
      .cursor-on .cursor > span.click:after {
        content: "click\A click"; }
      .cursor-on .cursor > span.next:after {
        content: "next\A project"; }
      .cursor-on .cursor > span.next-img:after {
        content: ""; }
      .cursor-on .cursor > span.prev:after {
        content: "previous\A project"; }
      .cursor-on .cursor > span.prev-img:after {
        content: ""; }
      .cursor-on .cursor > span.cross:after {
        content: "x"; }
      .cursor-on .cursor > span.relol:after {
        content: "click\A me";
        padding-top: calc((4rem / 2) - 5px); }
      .cursor-on .cursor > span.new:after {
        content: "new\A new"; }
      .cursor-on .cursor > span.mail:after {
        content: "mail"; }
  .cursor-on:active .cursor > span {
    transform: scale(0.75); }
    .cursor-on:active .cursor > span.link {
      transform: scale(1); }
    .cursor-on:active .cursor > span.play, .cursor-on:active .cursor > span.click, .cursor-on:active .cursor > span.relol, .cursor-on:active .cursor > span.new {
      transform: scale(1.5); }

body a {
  color: inherit; }
  body a:focus, body a:active {
    outline: none;
    text-decoration: none;
    color: inherit; }
  body a:hover {
    color: inherit;
    text-decoration: none; }

.template-paragraph p a,
.template-paragraph a {
  color: white;
  text-decoration: none;
  text-decoration-skip: ink;
  text-decoration-color: white;
  display: inline-block;
  position: relative; }
  .template-paragraph p a:before,
  .template-paragraph a:before {
    content: "";
    position: absolute;
    bottom: -0.5vw;
    left: 0;
    width: 100%;
    height: 0.25vw;
    background-color: #ffffff;
    opacity: 0.2; }
  .template-paragraph p a:after,
  .template-paragraph a:after {
    content: "";
    position: absolute;
    bottom: -0.5vw;
    left: 0;
    width: 100%;
    height: 0.25vw;
    background-color: #ffffff;
    opacity: 1;
    transition: all 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transform: scale3d(0, 1, 1);
    transform-origin: left center; }
  .template-paragraph p a:focus, .template-paragraph p a:hover,
  .template-paragraph a:focus,
  .template-paragraph a:hover {
    color: white;
    text-decoration-color: white; }
    .template-paragraph p a:focus:after, .template-paragraph p a:hover:after,
    .template-paragraph a:focus:after,
    .template-paragraph a:hover:after {
      transform: scale3d(1, 1, 1); }
  .template-paragraph p a:active, .template-paragraph p a:hover,
  .template-paragraph a:active,
  .template-paragraph a:hover {
    outline: 0; }

/* Responsive */
@media (max-width: 767px) {
  .container-fluid {
    padding-left: 20px;
    padding-right: 20px; }
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 20px;
    padding-right: 20px; }
  .row {
    margin-left: -20px;
    margin-right: -20px; }
  .two-column {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    -o-column-count: 1;
    column-count: 1; }
  header .container-fluid {
    padding-left: 15px;
    padding-right: 15px; }
  .section-home nav ul li[data-position] .nav-item {
    padding: 16px 0; }
  .section-home nav ul.bottom-menu {
    bottom: 0px; }
    .section-home nav ul.bottom-menu li {
      margin: 0 10px; }
  .section-archive nav {
    margin-bottom: 20px; }
    .section-archive nav > h3 {
      line-height: .88889; }
    .section-archive nav ul li {
      margin-top: 0;
      margin-bottom: 10px; }
  .template-list, .template-paragraph {
    padding-top: 20px;
    padding-bottom: 20px; }
  .template-list h3 {
    margin-bottom: 20px; }
  .template-paragraph .title {
    margin-bottom: 15px; }
  .template-simple {
    padding-bottom: 0; }
    .template-simple p, .template-simple h3 {
      margin-bottom: 20px; }
  .template-columns h3 {
    margin-top: 20px;
    margin-bottom: 20px; }
  .template-columns p {
    margin-top: 20px;
    margin-bottom: 20px; }
  .template-columns figure figcaption {
    padding: 7px 10px; }
  .template-list-new {
    padding-top: 20px;
    padding-bottom: 20px; }
    .template-list-new h2 {
      margin-bottom: 20px; }
    .template-list-new ul li {
      margin-bottom: 5px; }
  .logo a:not(#back-button) img {
    margin-left: 50px; }
  .image {
    display: none !important; }
  .image-cover.full-height,
  .video-cover.full-height {
    height: calc(100vh - 97px); }
  .template-quote blockquote {
    text-align: left; }
  /* Loader */
  .loader .mission-statement {
    padding-left: 20px;
    padding-right: 20px; }
  /* Project Detail */
  .project-slider .project .controls-project {
    position: absolute;
    left: 50%;
    margin-left: -17px;
    display: none; }
  .project-slider .project .prev-project {
    top: 20px; }
  .project-slider .project .next-project {
    bottom: 20px; }
  .project-slider .questionmark {
    bottom: 15px;
    left: 14px;
    top: initial; }
  .project-title {
    display: none; } }

@media (min-width: 992px) {
  .template-people .col:nth-child(3n+1) {
    clear: both; } }
