.template-list {
    padding-top: $space;
    padding-bottom: $space;

    h3 {
        margin-bottom: $space;
    }

    ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;

        .icon {
            display: block;
            width: 13px;
            height: 9px;
            background-image: url(../img/icon-check.png);
            background-image: url(../img/icon-check.svg);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 100%;
            float: left;
            // margin-top: 23px;
            margin-top: 11px;
        }

        li {
            padding-right: 10px;

            h6 {
                // margin-top: 15px;
                margin-top: 0;
                margin-bottom: 0;
                padding-left: 27px;
            }
        }
    }
}

.template-columns {

    figure {
        position: relative;

        img {
            width: 100%;
            display: block;
        }

        figcaption {
            background-color: #fff;
            @include font(17, 18);
            position: absolute;
            bottom: 0;
            right: 0;
            max-width: percentage(3/4);
            display: inline-block;
            padding: 10px 14px;

            p {
                margin: 0;
            }
        }
    }

    h3 {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    p {
        margin-bottom: 60px;
        margin-top: 60px;
    }

    @media (min-width: 768px) {

        .vertical-text {
            display: table;
            height: 50vw;

            article {
                display: table-cell;
                vertical-align: middle;
            }
        }
    }

}

.template-paragraph {
    padding-top: $space;
    padding-bottom: $space;

    iframe {
        width: 100%;
        height: 54vw;
        max-height: 550px;
    }

    .title {
        margin-bottom: 30px;
    }
}

.template-simple {
    padding-bottom: 0;

    &.template-paragraph {
        max-width: $s-max-width_page;
        margin-right: auto;
        margin-left: auto;

        h3 {
            @include fontsize(big, responsive);

        }

        &:nth-child(0) {
            @include fontsize(big, responsive);
        }

    }
}

.template-people {
    padding-top: $space;
    padding-right: 0;
    padding-left: 0;

    .row {
        padding-right: 0;
        padding-left: 0;
    }

    .title {
        text-align: center;
    }

    .person-item {
        margin-bottom: 35px;

        figure {
            img {
                width: 100%;
                height: auto;
            }

            figcaption {
                text-align: center;
                padding: 10px 5px 0;

                h4, & {
                    @include font(21, 24);
                    margin-bottom: 0;
                    margin-top: 0;
                }

                a {
                    color: inherit;
                }
            }
        }
    }
}

.template-quote {

    blockquote {
        display: block;
        text-align: center;
    }

    h1, p {
        margin: 0;
    }
}

.title {
    margin-top: 0;
    margin-bottom: 60px;
}

#map {
    height: 50vw;
    max-height: 500px;
}

.template-video {

    .video {
        iframe {
            width: 100%;
            height: 56.2vw;
            //max-height: 100vh;
            display: block;
        }
    }
}

.template-list-new {
    background-color: #000;
    color: #fff;
    text-align: center;
    padding-top: $space;
    padding-bottom: $space;

    h2 {
        @include font(60, 57);
        margin-bottom: $space;
    }

    ul {
        padding-left: 0;
        margin-bottom: 0;
        max-width: 746px;
        margin-right: auto;
        margin-left: auto;

        li {
            list-style-type: none;
            margin-bottom: 20px;

            a {
                @include font(32, 37);
                color: inherit;
                text-decoration: none;
                display: inline-block;

                &:hover {
                    color: #6E6E6E;
                }
            }
        }
    }
}

.template-maps {
    padding: 0 !important;

    .row {
        margin: 0;
    }

    @include bp(tablet) {
        padding: 0;
    }
}


div[title~=Lennep] img,
img[src="https://vanlennep.eu/wp-content/themes/vanlennep-2018/assets/img/map-pin.png"] {
    transform-origin: bottom center;
    transform: scale(0.5);
    @media (max-width: 767px) {
        transform: scale(0.3);
    }
}

