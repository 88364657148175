.full-height {
  position: relative;
  height: calc(100vh - 60px);
  //height: 600px;
}

.vertical {
  display: table-cell;
  vertical-align: middle;
}

.image-cover {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.col {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
