header {
	// height: $space;
	padding: $space / 3;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 10;
	margin: 0 auto;

	// @include bp(tablet) {
	// 	padding: $space / 2;
	// }



	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	div a {
		margin: 0;
	}

	.header-contact {
		a {
			color: black;
			text-decoration: none;
		}
		.social {
			float: left;
			margin-top: 12px;
		}

		.address {
			padding-left: 42px;
			padding-top: 6px;

			p {
				margin: 0;
				line-height: 18px;
			}

			a {
				font-size: 13px;
				line-height: 18px;
				font-weight: $bold;
			}
		}
	}

	.header-nav {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
		a {
			&:first-child {
				margin-right: $fs-medium_resp_desktop / 2;

				@include bp(desktop) {
					margin-right: $fs-medium_resp_desktop / 2;
				}
				@include bp(tablet) {
					margin-right: $fs-medium_resp_tablet / 2;
				}
				@include bp(mobile) {
					margin-right: $fs-medium_resp_mobile / 2;
				}
			}
		}
	}

	#back-button {
		font-size: 30px;
		color: black;
		float: left;
		display: block;
		margin-top: 3px;
		margin-right: 0;
		position: absolute;
		top: 7px;

		&:focus,
		&:active,
		&:visited,
		&:hover {
			color: inherit;
			text-decoration: none;
		}

		&:hover {
			opacity: 0.7;
		}
	}
}

.social-address {
	font-size: 13px;
	line-height: (22/13);
	font-weight: $bold;
	text-align: center;
	padding-bottom: 20px;

	p {
		margin-bottom: 0;
	}

	.social-fb {
		width: 30px;
		height: 30px;
		display: inline-block;
		background-color: white;
		border-radius: 50%;
		text-align: center;
		padding-top: 3px;
		margin-top: 10px;
		margin-bottom: 20px;

		img {
			width: 8px;
		}
	}

	a,
	a:hover,
	a:focus,
	a:active,
	a:visited {
		color: inherit;
		text-decoration: none;
	}
}

.project-title {
	text-transform: uppercase;
	font-size: 19px;
	line-height: 1;
	font-weight: $bold;
	height: $space;
	display: table;
	text-align: left;
	float: left;
	margin-right: 30px;
	width: 63%;

	.breadcrumb {
		height: 100%;
		display: table-cell;
		vertical-align: middle;
		padding-left: 0;
		margin-bottom: 0;
		list-style: none;

		li {
			display: inline-block;
			margin-right: 8px;
			cursor: default;

			&:after {
				content: '/';
				margin-left: 10px;
				display: inline-block;
			}

			&:last-child {
				margin-right: 0;

				&:after {
					content: '';
				}
			}

			strong {
				font-weight: $bold;
			}

			a {
				color: inherit;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}
