.project-detail {
    .full-height {
        height: 100vh;

    }

    .caption-holder {
        background-color: #fff;
        color: #000;
        position: absolute;
        top: 0px;
        left: -50%;
        height: 100%;
        overflow: auto;
        z-index: 5;
        padding-top: 8vw;
        // padding: 0 32px $space 20px;


        transition: left 800ms $t-smooth_one;

        .title {
            margin-bottom: 20px;
            text-transform: uppercase;

            @media (max-width: 420px) {
                margin-bottom: 8px;
            }
        }

        &.animated {
            left: 0;
        }

        .caption {
            height: 100%;
        }

        @include bp(tablet) {


            left: -100%;
            // padding-top: $space;

            &.animated {
                left: 0;
            }

            .title {
                // padding-right: 20px;
                // @include font(20, 22);
            }


            article {
                // @include font(20, 22);

                .contributors {
                    margin-top: 15px;
                }
            }

        }
        @include bp(mobile) {
            padding-top: 15vw;
        }
    }


    .title {
        @include fontsize(medium, responsive);

    }


    article {
        @include fontsize(small, responsive);


        p {
            a {
                color: #0000FF;
                text-decoration: none;

                &:hover {

                    border-bottom: 2px solid;
                }
            }
        }

        ul, ol {
        }

        ul.list {
            padding-left: 0;

            li {
                list-style: none;

                &:before {
                    // @include font_icon("\e607");
                    // font-size: 8px;
                    margin-right: 15px;
                }
            }
        }

        .contributors {
            @include fontsize(small, responsive);

            margin-top: 25px;

            ul {
                display: inline-block;
                margin-top: 0;
                padding-left: 0;

                li {
                    display: inline;
                    list-style-type: none;

                    a {
                        border-bottom: 1px solid #000;
                        color: inherit;

                        &:hover, &:focus, &:active {
                            text-decoration: none;
                        }

                        &:hover {
                            color: #6E6E6E;
                            border-color: #6E6E6E;
                        }
                    }

                    &:not(:last-child)::after {
                        content: ',';
                        display: inline-block;
                    }
                }
            }

        }

        @media (max-width: 420px) {
            font-size: 22px;
            margin-bottom: 4.045vw;
            line-height: 1.23;
            letter-spacing: -0.5px;
        }
    }

    @media (max-width: 767px) {
        .project-slider.full-height {
            overflow: visible;
            height: 56.25vw;
            margin-top: 60px;
        }
        .project {
            .bx-wrapper,
            .bx-viewport {
                height: 56.25vw!important;
            }
        }
        .caption-holder{
            height: auto;
            min-height: 100vh;
            padding-top: 0;
        }

        .info {
            position: fixed;
            line-height: 1.7;
            font-size: 21px;
            bottom: 10px;
            left: 40px;
        }

        .next-project-mobile {
            position: fixed;
            line-height: 1.7;
            font-size: 21px;
            bottom: 10px;
            right: 20px;
        }


        .mobile-caption-holder {
            position: relative;
            height: auto;
            left: 0!important;
            margin-top: 80px;
            padding-top: 0;
        }
        .image-cover.full-height, .video-cover.full-height {
            height: 56.25vw!important;
        }

        .bx-controls.bx-has-controls-direction {
            transform: translateY(0px);
            height: 120px;
        }
    }
}


.project .overlay {
    position: absolute;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
    z-index: 1;
}
