body {
	//  @include font(38, 39);
	font-family: $font-sans;
	text-rendering: geometricPrecision;
	overflow-x: hidden;
}


::selection {
	color: $c-white;
	background: $c-black;
}
.page-studio {
	::selection {
		color: $c-black;
		background: $c-white;
	}
}


h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-variant-ligatures: discretionary-ligatures;
	text-align: left;
	hyphens: none;
}

h1,
.h1 {
	font-size: $big_quote_bold;
	line-height: (72/60);
}

h2,
.h2 {
	font-size: 53px;
}

h3,
.h3 {
	font-size: $large_text_bold;
	line-height: (54/45);
}

h4,
.h4 {
	font-size: 29px;
}

h5,
.h5 {
	font-size: 28px;
}

h6,
.h6 {
	font-size: 25px;
}


.page-homepage,
footer {
	p, a {
		@include fontsize(big, responsive);
	}
}

.page-studio {
	p,
	footer p,
	footer a {
		@include fontsize(medium, responsive);
	}
	footer {
		max-width: $s-max-width_page;
		margin: 0 auto;
		p,
	  a {
			margin-bottom: 0;

		}
	}
}

.section-archive nav ul li h3,
.section-archive nav > h3 {
	// @include fluid-type(35px, 80px);
	@include fontsize(big, responsive);
	margin: 0;
}

.header-nav a {
	@include fontsize(medium, responsive);
	&:nth-child(1) {
		margin-right: -2px; // pixel perfect outlining
	}
}
