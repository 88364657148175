
.fullpage-slider {
  position: relative;

  .bx-controls {

    .bx-pager {
      text-align: center;
      position: absolute;
      bottom: 60px;
      left: 0;
      right: 0;

      .bx-pager-item {
        width: 24px;
        height: 24px;
        display: inline-block;
        margin: 0 15px;
        border-radius: 50%;

        a {
          background-color: transparent;
          overflow: hidden;
          width: 100%;
          height: 100%;
          display: block;
          border-radius: 50%;
          text-indent: 9999px;
          color: transparent;
          border: 2px solid white;

          &.active {
            background-color: white;
          }
        }
      }
    }
  }
}

.project-slider {
  position: relative;
  width: 100%;
  overflow: hidden;

  .video-cover {
    padding-left: 0;
    padding-right: 0;

    iframe {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .project {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .controls-project {
      position: absolute;
      opacity: 0;
      height: percentage(1/4);
      width: 100%;
      z-index: 3;
    }

    .slider {
      .col-sm-12.image-cover {
        padding: 0;
      }
    }

    .prev-project {
      top: 0;
      // &:hover {
      //   cursor: url("../img/icons/arrow-up.svg"), auto;
      // }
    }

    .next-project {
      bottom: 0;

      // &:hover {
      //   cursor: url("../img/icons/arrow-down.svg"), auto;
      // }

    }
  }

  .questionmark {
    padding: 5px 0;
    width: 40px;
    height: 40px;
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 22;
    transition: all 180ms linear;
    background: {
      image: url("../img/icons/plus.svg");
      repeat: no-repeat;
      position: center center;
    }
    @include bp(tablet) {
      width: 20px;
      height: 20px;
    }


    &:hover {
      border-color: white;
    }

    &.active {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

}

.no-touch {
  .project-slider,
  .fullpage-slider {

    .bx-controls-direction {
      a {
        position: absolute;
        top: 0;
        bottom: 0;
        width: percentage(1/4);
        z-index: 2;

        &:focus, &:active, &:visited {
          text-decoration: none;
          color: white;
        }

        &:hover {
          color: white;
          text-decoration: none;
        }

        &.bx-prev, &.bx-next {
          //opacity: 1;
        }

        &.bx-prev {
          left: 0;
          

          &:hover {
            // @media (min-width: 768px) {
            //   cursor: url("../img/icons/arrow-left.svg") 0 20, auto;
            // }
          }
          
        }

        &.bx-next {
          right: 0;

          &:hover {
            // @media (min-width: 768px) {
            //   cursor: url("../img/icons/arrow-right.svg") 80 20, auto;
            // }
          }

        }

        &.disabled {
          display: none;
        }
      }
    }
  }
}

.touch {

  .project-slider {
    .project {
      .controls-project {
        display: none !important;
      }
    }
  }

  .project-slider,
  .fullpage-slider {
    .bx-controls {
      a {
        position: absolute;
        top: 23px;
        bottom: 60px;
        z-index: 2;
        width: 58px;
        opacity: 1;
        background-repeat: no-repeat;
        cursor: pointer;

        &.bx-prev {
          left: 10px;
          background-image: url(../img/icons/arrow-left.svg);
          background-position: right center;

          @media (max-width: 767px) {
            left: 0;
          }
        }

        &.disabled {
          display: none;
        }

        &.bx-next {
          right: 10px;
          background-image: url(../img/icons/arrow-right.svg);
          background-position: left center;

          @media (max-width: 767px) {
            right: 0;
          }
        }
      }
    }
  }
}

/* Carousel */

.slick-slider .slick-list {
  height: calc(100vh - 60px) !important;
}

.slick-track {
  top: 50%;
  margin-top: -130px;

}
