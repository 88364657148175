body {
a {
  color: inherit;
  &:focus, &:active {
    outline: none;
    text-decoration: none;
    color: inherit;
  }
  &:hover {
    color: inherit;
    text-decoration: none;
  }
 }
}

// Inline link styling

$background-color: black;
$link-color: white;
$link-color-active: lighten(white, 90%);

$link-underline-color: $link-color;
$link-underline-color-active: $link-color-active;

$link-underline-width: 0.25vw;
$link-underline-offset: 0.5vw;

.template-paragraph {
p a,
a  {
// source: daanvanderzwaag.com 
	color: $link-color;
	text-decoration: none;
	text-decoration-skip: ink;
	text-decoration-color: $link-color;
	display: inline-block;
	position: relative;
	&:before {
		content: "";
		position: absolute;
		bottom: -$link-underline-offset;
		left: 0;
		width: 100%;
		height: $link-underline-width;
		background-color: $c-white;
		opacity: 0.2;
	}
	&:after {
		content: "";
		position: absolute;
		bottom: -$link-underline-offset;
		left: 0;
		width: 100%;
		height: $link-underline-width;
		background-color: $c-white;
		opacity: 1;
		transition: all 300ms $t-smooth_two;
		transform: scale3d(0, 1, 1);
		transform-origin: left center;
	}
	&:focus,
	&:hover {
		$color: $link-underline-color-active;
		color: $link-color-active;
		text-decoration-color: $link-underline-color-active;
		&:after {
			transform: scale3d(1, 1, 1);
		}
		// background-image: linear-gradient( to top, transparent, transparent $offset, $color $offset, $color ($offset + $weight), transparent ($offset + $weight));
	}
	&:active, &:hover {
    outline: 0;
	}
}

}
