
/* Grid */
.people-grid {
  display: block;
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: $s-main / 2;
  max-width: $s-max-width_page;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  @include bp(tablet) {
    grid-template-columns: 1fr 1fr;
  }
  @include bp(mobile) {
    grid-template-columns: 1fr;
    grid-auto-rows: 80vh;
  }
  @include bp(tablet) {
    grid-gap: $space / 3;
  }
  @include bp(desktop) {
    grid-gap: $space / 2;
  }
}

.people-grid--item {

  $s-item_mobile: 55vw;
  $s-item_tablet: 65vw;
  $s-item_desktop_xs: 45vw;
  $s-item_desktop: 35vw;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-bottom: $s-main / 2;
  margin-right: $s-main;
  width: calc(100% / 3 - (#{$s-main / 2} + #{$s-main / 3}));
  &:nth-child(3n) {
    margin-right: 0;
  }
  @include bp(maxwidth) {

  }
  @include bp(tablet) {
    width: calc(100% / 2 - (#{$s-main / 3}));
      margin-right: $s-main / 2;
    &:nth-child(3n) {
      margin-right: $s-main / 2;
    }
    &:nth-child(2n) {
      margin-right: 0;
    }


  }
  @include bp(mobile) {
    width: 100%;
    margin-right: 0;

    &:nth-child(2n),
    &:nth-child(3n) {
      margin-right: 0;
    }


  }

  h3 {
    color: $c-white;
    position: relative;
    @include fontsize(medium, responsive);

    &:after {
      content: attr(data-job);
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }
  }

  .item--container {
    position: relative;
    background-color: $c-white;
    height: 100%;
    .item--text {
      position: relative;
      height: 100%;
      cursor: inherit;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: absolute;
      left: 0;
      top: 0;
      padding: $s-main / 2;
      z-index: 3;
      .item--text__subtitle,
      .item--text__title {
        // opacity: 0;
        transform: rotate(0) rotateX(0) translateX(-110%) translateY(0) scale(1) translateZ(0);
        margin: 0 0 0.75rem;
        color: $c-black;
        z-index: 2;
      }

      .item--text__title {
        @include fontsize(medium, responsive);
        transition: transform 920ms $t-smooth_one;
        font-weight: 400;
        hyphens: none;
        //text-transform: uppercase;
      }

      .item--text__subtitle {
        @include fontsize(small, responsive);
        transition: transform 600ms $t-smooth_one;
        font-weight: 200;
        justify-self: flex-end;
        padding-right: $s-main;
      }
    }

    .people-card--image {
      z-index: 0;
      display: block;
      width: 100%;
      // height: 100%;
      overflow: hidden;
      object-fit: cover;


      img {
        height: 100%;
        width: 100%;
        z-index: -1;
        object-fit: cover;
      }

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: $c-white;
        z-index: 2;
        transform: rotate(0) rotateX(0) translateX(-110%) translateY(0) scale(1) translateZ(0);
        transition: transform 600ms $t-smooth_one;
      }
    }
  }
  @media screen and (min-width: 767px) {
  &:hover {
    a {
      color: $c-black;
      filter: none;
    }

    h3 {
      span {
        opacity: 0;
      }

      &:after {
        content: attr(data-job);
        opacity: 1;
      }
    }

    .item--container {
      .item--text__subtitle,
      .item--text__title {
        transform: rotate(0) rotateX(0) translateX(0) translateY(0) translateZ(0);
      }

      .item--text__subtitle {
        transition: transform 920ms $t-smooth_one 125ms;
      }

      .people-card--image {
        &:before {
          transform: rotate(0) rotateX(0) translateX(0) translateY(0) translateZ(0);
        }
      }
    }
  }
 }
}
