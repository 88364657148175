/* Fonts */

@font-face {
  font-family: 'plain-medium';
  src: url('#{$font-path}/plain-medium.eot');
  src: url('#{$font-path}/plain-medium.woff2') format('woff2'),
       url('#{$font-path}/plain-medium.woff') format('woff'),
       url('#{$font-path}/plain-medium.ttf') format('truetype'),
       url('#{$font-path}/plain-medium.svg#plain-medium') format('svg'),
       url('#{$font-path}/plain-medium.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'VL_handwrite_V1.0.1';
    src: url('#{$font-path}/VL_handwrite_V101-regular.eot');
    src: url('#{$font-path}/VL_handwrite_V101-regular.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path}/VL_handwrite_V101-regular.woff2') format('woff2'),
        url('#{$font-path}/VL_handwrite_V101-regular.woff') format('woff'),
        url('#{$font-path}/VL_handwrite_V101-regular.ttf') format('truetype'),
        url('#{$font-path}/VL_handwrite_V101-regular.svg#VL_handwrite_V1.0.1-regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'Till2_37-Regular';
  src: url('#{$font-path}/Till2_37-Regular.eot');
  src: url('#{$font-path}/Till2_37-Regular.woff2') format('woff2'),
       url('#{$font-path}/Till2_37-Regular.woff') format('woff'),
       url('#{$font-path}/Till2_37-Regular.ttf') format('truetype'),
       url('#{$font-path}/Till2_37-Regular.svg#Till2_37-Regular') format('svg'),
       url('#{$font-path}/Till2_37-Regular.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'VL_handwrite_V1.0.2-regular';
  src: url('#{$font-path}/VL_handwrite_V1.0.2-regular.eot');
  src: url('#{$font-path}/VL_handwrite_V1.0.2-regular.woff2') format('woff2'),
       url('#{$font-path}/VL_handwrite_V1.0.2-regular.woff') format('woff'),
       url('#{$font-path}/VL_handwrite_V1.0.2-regular.ttf') format('truetype'),
       url('#{$font-path}/VL_handwrite_V1.0.2-regular.svg#Till2_37-Regular') format('svg'),
       url('#{$font-path}/VL_handwrite_V1.0.2-regular.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}



t-face {
  font-family: 'custom_icons';
  src:url(../fonts/custom_icons.eot?dxlehx);
  src:url(../fonts/custom_icons.eot?#iefixdxlehx) format('embedded-opentype'),
  url(../fonts/custom_icons.ttf?dxlehx) format('truetype'),
  url(../fonts/custom_icons.woff?dxlehx) format('woff'),
  url(../fonts/custom_icons.svg?dxlehx#custom_icons) format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'custom_icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down:before {
  content: "\e600";
}
.icon-arrow-left:before {
  content: "\e601";
}
.icon-arrow-right:before {
  content: "\e602";
}
.icon-arrow-up:before {
  content: "\e603";
}
.icon-back:before {
  content: "\e604";
}
.icon-close:before {
  content: "\e605";
}
.icon-question-mark:before {
  content: "\e606";
}
.icon-success:before {
  content: "\e607";
}
