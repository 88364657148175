.page-studio {
  background-color: $c-black;
  color: $c-white;

  .container-fluid {
    display: flex;
    flex-direction: column;
  }

  header {}

  footer {
    color: $c-white;
    padding: $space $space / 3;
    margin-bottom: $space;
      @include bp(tablet) {
        padding: $space / 2 $space / 3 ;
        margin-bottom: $space / 2;
      }
  }

  section.studio--people {
    margin-top: 5vw;
  }

  section.studio--map {
    margin-top: 5vw;
    width: calc(100% + 40px);
    margin-left: -20px;
  }
}
