blockquote {
  padding: 0;
  margin: 0;
  font-size: inherit;
  border-left: 0;
}

.two-column {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;

  -webkit-column-gap: 60px;
  -moz-column-gap: 60px;
  column-gap: 60px;
}

/* Buttons */

.btn-circle {
  width: 24px;
  height: 24px;
  display: block;
  background-color: transparent;
  border: 2px solid white;
  color: white;
  font-size: 8px;
  text-align: center;
  border-radius: 50%;
  line-height: 1;
  padding: 6px 0;

  &:focus, &:active, &:visited {
    text-decoration: none;
    color: white;
  }

  &:hover {
    background-color: black;
    color: white;
    text-decoration: none;
  }
}

/* Hamburger */

.hamburger {
  width: 34px;
  display: block;
  float: right;
  margin-top: 22px;

  .line {
    display: block;
    height: 2px;
    background-color: black;
    margin-bottom: 11px;

    &:last-child {
      margin-bottom: 0;
    }

    -webkit-transition: -webkit-transform .35s linear;
    -moz-transition: -moz-transform .35s linear;
    transition: transform .35s linear;

  }

  &.active {
    .line {
      &:first-child {
        -webkit-transform: rotate(45deg) translate(5px, 5px);
        -moz-transform: rotate(45deg) translate(5px, 5px);
        transform: rotate(45deg) translate(5px, 5px);
      }

      &:last-child {
        -webkit-transform: rotate(-45deg) translate(5px, -4px);
        -moz-transform: rotate(-45deg) translate(5px, -4px);
        transform: rotate(-45deg) translate(5px, -4px);
      }
    }
  }
}

#next-section {
  position: fixed;
  bottom: 30px;
  left: 50%;
  margin-left: -31px;
  z-index: 3;
  display: none;
}


.headroom {
  will-change: transform;
  transition: transform 200ms linear;
  -webkit-transition: -webkit-transform 200ms linear;
}
.headroom--pinned {
  transform: translateY(0%);
  -webkit-transform: -webkit-translateY(0%);

}
.headroom--unpinned {
  transform: translateY(-100%);
  -webkit-transform: -webkit-translateY(-100%);
}
