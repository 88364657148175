//$space: 30px;
$space: 20px;
@media (max-width: 767px) {
  @import "responsive/mobile";
}



@media (min-width: 992px) {
  .template-people {
    .col {
      &:nth-child(3n+1) {
        clear: both;
      }
    }
  }
}

//@import "responsive/apple-screens";
