
// Original code


/* Loader */

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: $c-black;
  color: $c-white;
  text-align: center;
  //display: none;

  .mission-statement {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    display: table;
    width: 100vw;

    .ms-content {
      display: table-cell;
      vertical-align: middle;
      width: 100%;
      height: 100%;
      img {
        margin-bottom: 60px;
        max-width: 90%;
      }

      h1 {
        text-transform: uppercase;
        margin-bottom: 0;
      }

      h3 {
        font-weight: normal;
        margin: 0;
        // @include font(54, 54);
      }
    }
  }
}


// 2018 adjustments

.loader {
  overflow: hidden;
  transform:translateY(0);
  transition: transform 920ms $t-smooth_two;
  z-index: 11;
  .mission-statement {
    transform: translateY(0);
    transition: transform 800ms $t-smooth_one;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;

  }
  &.vl-slideUp {
// visibility: hidden;
    transform: translateY(-100%);
    .mission-statement {
      transform: translateY(-75%);
      // visibility: hidden;

    }
  }

}

body {
.header--video:before {
  // background: transparent;
  // transform: translateY(0);
   transform: translateY(-100%);

  //transition: 800ms transform 800ms $t-smooth_one;
}
&.loaded {
  .header--video:before {
  //  transform: translateY(-100%);
  }
 }
}
