section.section-home_alt {
  background-color: $c-white;
}
.home--header {
  .header--video {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    iframe, embed, object {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &:after, &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: all;
      background: rgba($c-white, 0);
    } &:before {
      background: rgba($c-white, 1);
      z-index: 2
    }
  }
  .header--nav {
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
    }

  }
}

.home--intro {
  margin-bottom: 10%;
  margin-top: 5%;
}
