html, body {
  height: 100%;
}

body {
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.page-homepage,
  &.page-work,
  &.page-studio {
    padding-top: $space * 2;
    @include bp(tablet) {
      padding-top: $space;
    }
  }
  &.page-homepage {
    max-width: $s-max-width_page;
    margin: 0 auto;
  }
}

.body-black {
  background-color: black;
  color: white;
}

.container, .container-fluid {
    padding-left:  $space / 3;
    padding-right:  $space / 3;
}
