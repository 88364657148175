
.footer {
  display: flex;
  flex-direction: row;
  color: $c-black;
  text-align: left;
  padding: 0;
  max-width: $s-max-width_page;
  padding: $space / 3;

  @include bp(mobile) {
    flex-direction: column;
    padding-top: 0;
  }

  &--address,
  &--links {
    margin-bottom: $space / 3;
  }


  &--address {
    a:hover {
      text-decoration: underline;
    }
  }

  h2 {
    margin-bottom: $space;
  }
  address {
    margin-bottom: 0;
    p, a {
      margin-bottom: 0;
    }
  }
  ul {
    padding-left: 0;
    max-width: 746px;
    margin-right: auto;
    margin-left: auto;

    li {
      list-style-type: none;
    }
  }

}
