$c-black: #000000;
$c-white: #ffffff;
$c-contrast: #f8e71c;
$c-contrast_two: #0000ed;


$s-main: 4rem;
$s-cursor: $s-main;

// Cursor

.cursor-on {
  cursor: none;

  * {
    cursor: none;
  }
  .cursor {
    position: fixed;
    z-index: 10;
    pointer-events: none;
    top: -$s-cursor / 2;
    left: -$s-cursor / 2;
    > span {
      display: block;
      width: $s-cursor;
      height: $s-cursor;
      border-radius: $s-cursor;
      background: $c-black;
      color: $c-white;
      transition: transform 180ms $t-smooth_one;
      transform: scale(1);
      &.off {
        transform: scale(0);
      }
      &.link {
        transform: scale(1.25);
        border: 4px solid $c-black;
        background: transparent;
        transition: background 180ms $t-smooth_one;
        &.external {
          border: 4px solid $c-black;
          background: $c-black;
          &:after {
            content: "↗";
            display: block;
            white-space: pre;
            color: $c-white;
            font-weight: 100;
            font-size: 2rem;
            text-align: center;
            width: 100%;
            line-height: 1;
            padding-top: 4px;
          }
        }
        &.next,
        &.prev,
        &.next-img,
        &.prev-img,
        &.cross {
          background: $c-black;
        }

        &.next-img {
          background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA2NCA2NCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNjQgNjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiNGRkZGRkY7fQ0KPC9zdHlsZT4NCjxwb2x5Z29uIGlkPSJYTUxJRF82XyIgY2xhc3M9InN0MCIgcG9pbnRzPSIxNC41LDMwLjQgNDIuNSwzMC40IDMyLjUsMjAuNCAzNC43LDE4LjEgNDYuMiwyOS42IDQ2LjIsMjkuNiA0OC41LDMxLjggNDguNSwzMS44IA0KCTQ4LjUsMzEuOCA0Ni4yLDM0LjEgNDYuMiwzNC4xIDM0LjcsNDUuNiAzMi41LDQzLjMgNDIuMSwzMy42IDE0LjUsMzMuNiAiLz4NCjwvc3ZnPg0K)!important;
        }
        &.prev-img {
          background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA2NCA2NCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNjQgNjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiNGRkZGRkY7fQ0KPC9zdHlsZT4NCjxwb2x5Z29uIGlkPSJYTUxJRF82XyIgY2xhc3M9InN0MCIgcG9pbnRzPSI0OC41LDMwLjQgMjAuNSwzMC40IDMwLjUsMjAuNCAyOC4yLDE4LjEgMTYuOCwyOS42IDE2LjgsMjkuNiAxNC41LDMxLjggMTQuNSwzMS44IA0KCTE0LjUsMzEuOCAxNi44LDM0LjEgMTYuOCwzNC4xIDI4LjIsNDUuNiAzMC41LDQzLjMgMjAuOCwzMy42IDQ4LjUsMzMuNiAiLz4NCjwvc3ZnPg0K)!important;
        }
        &.next-img,
        &.prev-img {
          background: $c-black;
          background-size: 100%;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
      &.light {
        background: $c-white;
        &.link {
          background: transparent;
          border-color: $c-white;
        }
        &.mail {
            background: #0000ed;
            border: none;
        }
      }

      &.play,
      &.click,
      &.next,
      &.prev,
      &.next-img,
      &.prev-img,
      &.cross,
      &.relol,
      &.new,
      &.mail {
        transform: scale(1.5);
      }
      &.play:after,
      &.click:after,
      &.next:after,
      &.prev:after,
      &.next-img:after,
      &.prev-img:after,
      &.cross:after,
      &.relol:after,
      &.new:after,
      &.mail:after {
        display: block;
        content: "";
        white-space: pre;
        font-size: 8px;
        text-align: center;
        width: 100%;
        line-height: 1;
        padding-top: calc((#{$s-cursor} / 2) - 4px); // = cursor / 2 - font-size / 2
        color: $c-white;
      }
      &.play:after,
      &.play.pause:after {
        content: "play";
          &:active {
            .cursor > span {
            transform: scale(1.25);
          }
        }
      }
      &.next:after,
      &.prev:after,
      &.next-img:after,
      &.prev-img:after {
        padding-top: 7px;
      }
      &.play:after {
        content: "pause";
      }
      &.play.pause:after {
        content: "play";
      }
      &.click:after {
        content: "click\A click";
      }
      &.next:after {
        content: "next\A project";
      }
      &.next-img:after {
        content: "";
      }
      &.prev:after {
        content: "previous\A project";
      }
      &.prev-img:after {
        content: "";
      }

      &.cross:after {
        content: "x";
      }
      &.relol:after {
        content: "click\A me";
        padding-top: calc((#{$s-cursor} / 2) - 5px) // = cursor / 2 - font-size
      }
      &.new:after {
        content: "new\A new";
      }
      &.mail:after {
        content: "mail";
      }
    }
  }
  &:active .cursor > span {
    transform: scale(0.75);
    &.link {
      transform: scale(1);
    }
    &.play,
    &.click,
    &.relol,
    &.new {
      transform: scale(1.5);
    }
  }
  // @include bp(tablet) {
  //   cursor: initial;
  //   .cursor {
  //     display: none;
  //     * {
  //       cursor: initial;
  //     }
  //   }
  // }
}
