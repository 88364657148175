@mixin absolute_full_height() {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin font_icon($content) {
  content: $content;
  font-family: 'custom_icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin font($size, $line-height) {
  font-size: $size+px;
  line-height: ($line-height/$size);
}

// Breakpoint mixin

@mixin bp($point) {
  $bp-mobile: "(max-width: 420px)";
  $bp-tablet: "(max-width: 768px)";
  $bp-desktop: "(max-width: 1400px)";
  $bp-maxwidth: "(min-width: #{$s-max-width_page})";


  @if $point == desktop {
    @media #{$bp-desktop} {
      @content;
    }
  } @else if $point == maxwidth {
    @media #{$bp-maxwidth} {
      @content;
    }
  }
   @else if $point == tablet {
    @media #{$bp-tablet} {
      @content;
    }
  } @else if $point == mobile {
    @media #{$bp-mobile} {
      @content;
    }
  }
}

// Fluid type: between two numbers calculate pixels

@mixin fluid-type($min-font-size: 12px, $max-font-size: 21px, $lower-range: 420px, $upper-range: 900px) {

  font-size: calc(#{$min-font-size} + #{(($max-font-size / ($max-font-size * 0 + 1)) - ($min-font-size / ($min-font-size * 0 + 1)))} * ( (100vw - #{$lower-range}) / #{(($upper-range / ($upper-range * 0 + 1)) - ($lower-range / ($lower-range * 0 + 1)))}));

  @media screen and (max-width: $lower-range) {
    font-size: $min-font-size;
  }
  @media screen and (min-width: $upper-range){
    font-size: $max-font-size;
  }
}

// Responsive & fixed typography mixin
//
// 3 Types of typo: small, medium, big
// 3 Steps based on viewport w/ breakpoint mixin
// 2 Choices in measurments: absolute (PX) or relative (VW)

@mixin fontsize($size:big, $mode:responsive) {
  line-height: 1.15;

  @if $mode == "fixed" {

    @if $size == "small" {

      @include bp(desktop) {
        font-size: $fs-small_fixed_desktop;
      }
      @include bp(tablet) {
        font-size: $fs-small_fixed_tablet;
      }
      @include bp(mobile) {
        font-size: $fs-small_fixed_mobile;
      }
    } @else if $size == "medium" {
      @include bp(desktop) {
        font-size: $fs-medium_fixed_desktop;
      }
      @include bp(tablet) {
        font-size: $fs-medium_fixed_tablet;
      }
      @include bp(mobile) {
        font-size: $fs-medium_fixed_mobile;
      }
    } @else if $size == "big" {
      @include bp(desktop) {
        font-size: $fs-big_fixed_desktop;
      }
      @include bp(tablet) {
        font-size: $fs-big_fixed_tablet;
      }
      @include bp(mobile) {
        font-size: $fs-big_fixed_mobile;
      }
    }
  } @else if $mode == "responsive" {
    @if $size == "small" {
      font-size: $fs-small_resp_desktop;
      margin-bottom: $fs-small_resp_desktop;

      @include bp(desktop) {
        font-size: $fs-small_resp_desktop;
        margin-bottom: $fs-small_resp_desktop;

      }
      @include bp(tablet) {
        font-size: $fs-small_resp_tablet;
        margin-bottom: $fs-small_resp_tablet;

      }
      @include bp(mobile) {
        font-size: $fs-small_resp_mobile;
        margin-bottom: $fs-small_resp_mobile;

      }
    } @else if $size == "medium" {
      font-size: $fs-medium_resp_desktop;
      margin-bottom: $fs-medium_resp_desktop;


      @include bp(desktop) {
        font-size: $fs-medium_resp_desktop;
        margin-bottom: $fs-medium_resp_desktop;
      }
      @include bp(tablet) {
        font-size: $fs-medium_resp_tablet;
        margin-bottom: $fs-medium_resp_tablet;

      }
      @include bp(mobile) {
        font-size: $fs-medium_resp_mobile;
        margin-bottom: $fs-medium_resp_mobile;

      }
    } @else if $size == "big" {
      font-size: $fs-big_resp_desktop;

      @include bp(desktop) {
        font-size: $fs-big_resp_desktop;

      }
      @include bp(tablet) {
        font-size: $fs-big_resp_tablet;

      }
      @include bp(mobile) {
        font-size: $fs-big_resp_mobile;

      }
    }
  }
}
