nav {
	ul {
		padding-left: 0;
		margin-bottom: 0;
		list-style: none;
		text-align: center;

		li {
			margin-top: $space;
			margin-bottom: $space;
			position: relative;
			z-index: 1;
			cursor: default;

			.nav-item {
				display: inline-block;
			}

			// -webkit-transition: color .25s linear;
			// -o-transition: color .25s linear;
			// transition: color .25s linear;

			h3 {
				//text-transform: uppercase;
				margin-top: 0;
				margin-bottom: 0px;
			}

			a {
				color: inherit;
				text-decoration: none;
				//text-transform: uppercase;

				&:hover,
				&:focus,
				&:active {
					text-decoration: none;
					color: inherit;
				}
			}

			p {
				margin-bottom: 0;
				line-height: 25px;
			}
		}
	}

	&.nav-hidden {
		position: absolute;
		top: 60px;
		left: 0;
		right: 0;

		li {
			opacity: 0;
			z-index: 3;
		}
	}
}

.section {
	z-index: 2;
	position: relative;
}

.section-home_old {
	position: fixed;
	top: 0px;
	left: 0;
	right: 0;
	height: 100%;
	overflow: auto;
	padding-top: 60px;
	background-color: red;
	color: white;
	z-index: 9;
	visibility: hidden;
	display: none;

	-webkit-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
	-o-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
	animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);

	-webkit-animation-duration: 1s;
	-o-animation-duration: 1s;
	animation-duration: 1s;

	&.animated {
		display: block;
		visibility: visible;
	}

	nav {
		ul {
			padding: 20px 0;

			li {
				h3 {
					//line-height: 35px;
					margin-bottom: 0;
					@include font(45, 54);
				}

				text-transform: uppercase;
			}
		}

		//@media (min-width: 768px) {
		// position: absolute;
		// top: 60px;
		// bottom: 0;
		// left: 0;
		// width: 100%;
		// z-index: 2;

		&.nav-desktop {
			@media (min-width: 768px) {
				min-height: 538px;
			}
		}

		ul {
			position: absolute;
			left: 0;
			right: 0;

			&:not(.bottom-menu) {
				top: 50%;
				-webkit-transform: translateY(-55%);
				-moz-transform: translateY(-55%);
				transform: translateY(-55%);
			}

			li {
				display: table;
				margin: 0 auto;

				.nav-item {
					h3 {
						@include font(54, 65);
					}

					p {
						display: none;
					}
				}

				&[data-position] {
					.nav-item {
						padding: 44px 0;
						//display: table-cell;
						//vertical-align: middle;
					}
				}
			}

			&.bottom-menu {
				bottom: 14px;

				li {
					display: inline-block;
					margin: 0 $space/2;

					a {
						@include font(35, 43);

						&:hover {
							color: #6e6e6e;
						}
					}
				}
			}
			//}
		}
	}

	.news-item {
		position: absolute;
		top: 46%;
		right: calc(50% - 330px);
	}

	&.slideInDown {
		+ .project-detail {
			.questionmark {
				@media (min-width: 768px) {
					display: none;
				}
			}
		}
	}
}

.section-archive {
	nav {
		margin-bottom: $space;

		 h3 {
			// font-size: $f-big_resp;
			margin-top: 0;
			// margin-bottom: 5px;
			// padding-bottom: 10px;
		}

		ul {
			li {
				text-align: left;
				margin: 0;
				margin-bottom: 5px;

				.nav-item {
					display: block;
				}


				a {
					&:hover {
				//		color: #aaa;
					}
				}
			}
		}
	}
}

.image {
	position: fixed;
	top: calc(6.15vw + 20px);
	right: 20px;
	display: table;
	//width: 100%;
	height: calc(100% - 60px);
	z-index: 1;
	pointer-events: none;



	figure {
		display: table-cell;
		width: 100%;
		height: 100%;
		//padding: 30px;
		padding: 0;

		img {
			position: relative;
			z-index: 2;
			height: auto;
			//max-height: calc(100% - 210px);
			//max-width: 100%;
			width: auto;
			// transition: all 300ms ease-in-out;
		}
	}

	&.landscape {
		figure img {
			width: auto;
			max-height: 21vw;
		}
	}
	&.portrait {
		figure img {
			max-width: 21vw;
			height: auto;
		}
	}

	&.top-left {
		figure {
			vertical-align: top;
			text-align: left;
		}
	}

	&.top-right {
		figure {
			vertical-align: top;
			text-align: right;
		}
	}

	&.bottom-left {
		figure {
			vertical-align: bottom;
			text-align: left;
		}
	}

	&.bottom-right {
		figure {
			vertical-align: bottom;
			text-align: right;
		}
	}
}

.page-archive {
	.image {
		&.top-left,
		&.top-right,
		&.bottom-left,
		&.bottom-right {
			figure {
				opacity: .6;
				vertical-align: middle;
				text-align: right;
			}
		}
	}
}

.page-home {
	.section-home {
		visibility: visible !important;
		display: block !important;
	}

	.hamburger {
		display: none;
	}
}
