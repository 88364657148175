
.container-fluid {
  padding-left: $space;
  padding-right: $space;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  padding-left: $space;
  padding-right: $space;
}

.row {
  margin-left: -$space;
  margin-right: -$space;
}

.two-column {
  -webkit-column-count: 1;
  -moz-column-count: 1;
  -o-column-count: 1;
  column-count: 1;
}

header {
  //border-bottom: 2px solid black;

  .container-fluid {
    padding: {
      left: 15px;
      right: 15px;
    }
  }
}

nav {
  ul {
    li {
      h3 {
        //font-size: 25px;
        //line-height: 1;
      }
    }
  }
}

.section-home {
  nav {
    ul {
      li {
        &[data-position] {
          .nav-item {
            padding: 16px 0;
          }
        }

        .nav-item {
          h3 {
            // @include font(45, 54);
          }
        }
      }

      &.bottom-menu {
        bottom: 0px;

        li {
          margin: 0 10px;
        }
      }
    }
  }
}

//nav.nav-mobile {
//  height: calc(100vh - 60px);
//  overflow: hidden;
//
//  ul {
//
//    li {
//      margin-top: $space;
//      margin-bottom: $space;
//      // word-break: break-word;
//
//      h3 {
//        // word-break: break-all;
//      }
//
//      .nav-item {
//        height: 120px;
//        width: 100vw;
//        display: table-cell;
//        vertical-align: middle;
//      }
//
//      .nav-item-news {
//        img {
//          display: inline;
//        }
//      }
//
//      &.slick-active {
//        .nav-item {
//          color: white;
//        }
//
//        .list-image {
//          opacity: 1;
//
//          -webkit-transition: opacity .3s ease;
//          -o-transition: opacity .3s ease;
//          transition: opacity .3s ease;
//        }
//      }
//
//      .list-image {
//        width: 100%;
//        height: 100%;
//        position: absolute;
//        top: 0;
//        left: 0;
//        z-index: -1;
//        opacity: 0;
//        background-size: contain;
//        background-repeat: no-repeat;
//        background-position: center center;
//      }
//    }
//
//  }
//}

//nav.nav-desktop {
//  ul {
//    padding-top: 10px;
//
//    &.bottom-menu {
//      margin-top: -30px;
//    }
//
//    li {
//      margin-top: 0;
//      margin-bottom: 0;
//      color: white !important;
//
//      &:hover {
//        color: white !important;
//      }
//
//      h3, a {
//        font-size: 25px;
//        line-height: (40/25);
//        margin-bottom: 0;
//      }
//
//      p {
//        display: none;
//      }
//    }
//  }
//}

.section-archive {

  nav {
    margin-bottom: $space;

    > h3 {
      // font-size: 27px;
      line-height: .88889;
    }

    ul {

      li {
        margin-top: 0;
        margin-bottom: 10px;

        h3 {
          // @include font(27, 24);
        }
      }
    }
  }
}

.template-list, .template-paragraph {
  padding-top: $space;
  padding-bottom: $space;

  // p {
  //   @include font(20, 22);
  // }
  //
  // .title {
  //   @include font(35, 31);
  // }
}

.template-list {
  h3 {
    margin-bottom: $space;
  }
}

.template-paragraph {
  .title {
    margin-bottom: 15px;
  }
}

.template-simple {
  padding-bottom: 0;

  p, h3 {
    margin-bottom: $space;
  }
  //
  // h3 {
  //   @include font(35, 31);
  // }
  //
  // p {
  //   @include font(20, 22);
  // }
}

.template-columns {
  h3 {
    margin-top: $space;
    margin-bottom: $space;
    // @include font(35, 31);
  }

  p {
    margin-top: $space;
    margin-bottom: $space;
    // @include font(20, 22);
  }

  figure {
    figcaption {
      padding: 7px 10px;
      // @include font(12, 14);
    }
  }
}

.template-list-new {
  padding-top: $space;
  padding-bottom: $space;

  h2 {
    // @include font(35, 31);
    margin-bottom: $space;
  }

  ul {
    li {
      margin-bottom: 5px;
      a {
        // @include font(20, 22);
      }
    }
  }
}

.logo {

  a:not(#back-button) {
    img {
      margin-left: 50px;
    }
  }
}

.image {
  display: none !important;
}

.image-cover.full-height,
.video-cover.full-height {
  height: calc(100vh - 97px);
  //height: 200px;
}

.template-quote {
  blockquote {
    text-align: left;
  }

  h1 {
    // font-size: 45px;
    // line-height: (54/45);
    //@include font(35, 31);
  }
}

/* Loader */

.loader {
  .mission-statement {
    padding-left: $space;
    padding-right: $space;

    .ms-content {
      h1 {
        // font-size: 45px;
        // line-height: (54/45);
      }

      h3 {
        // font-size: 25px;
        // line-height: 1.1;
      }
    }
  }
}

/* Project Detail */


.project-slider {

  .project {
    .controls-project {
      position: absolute;
      left: 50%;
      margin-left: -17px;
      display: none;
    }

    .prev-project {
      top: $space;
    }

    .next-project {
      bottom: $space;
    }
  }

  .questionmark {
    bottom: 15px;
    left: 14px;
    top: initial;
  }
}

.project-title {
  display: none;
}
