section.section-archive {
  .container-fluid {
    //padding: 0;
  }
  h2 {
    font-family: $font-till;
    color: #000;
    text-transform: uppercase;
    font-size: 4vw;
    margin: 1vw 0;
    @include bp(tablet) {
      font-size: 6vw;
      margin: 1.5vw 0;
    }
    @include bp(mobile) {
      font-size: 6vw;
      margin: 1.5vw 0;
    }

  }
  .highlights {

    width: calc(100% + #{$space});
    margin-left: -$space / 2;
    padding: $space / 2;
    padding-top: 0;
    position: relative;
    margin-bottom: $space;
    &:after {
      position: absolute;
      left: $space / 2;
      bottom: -$space / 2;
      content: '';
      width: calc(100% - #{$space});
      height: $space / 3;
      background-image: url(../img/vl-line.svg);
      background-size: contain;
      background-repeat: no-repeat;
      @include bp(tablet) {
        background-image: url(../img/vl-line_medium.svg);
        bottom: 0;

      }
      @include bp(mobile) {
        background-image: url(../img/vl-line_small.svg);

      }
    }
    @include bp(tablet) {
      margin-bottom: 0;
    }
  }

}
// Work pages

body.page-work_item {
  padding-top: 0;
  .full-height {
    height: 100vh;
  }
}
